import React from 'react';
import {
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';
import '../../style/AIChatBot/DataVisualization.css';

// Chart Colors and Styles
const COLORS = {
  primary: ['#3B82F6', '#60A5FA', '#93C5FD', '#BFDBFE', '#DBEAFE', '#EFF6FF'],
  success: ['#10B981', '#34D399', '#6EE7B7', '#A7F3D0', '#D1FAE5', '#ECFDF5'],
  warning: ['#F59E0B', '#FBBF24', '#FCD34D', '#FDE68A', '#FEF3C7', '#FFFBEB'],
  error: ['#EF4444', '#F87171', '#FCA5A5', '#FECACA', '#FEE2E2', '#FEF2F2'],
  neutral: ['#6B7280', '#9CA3AF', '#D1D5DB', '#E5E7EB', '#F3F4F6', '#F9FAFB'],
};

const CHART_STYLE = {
  animation: { duration: 1500 },
  fontSize: 12,
  fontFamily: 'Inter, system-ui, sans-serif',
};

// Custom Components
const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="tooltip">
      <p className="tooltip-label">{label}</p>
      {payload.map((entry, index) => (
        <div key={index} className="tooltip-entry">
          <div
            className="tooltip-color-dot"
            style={{ backgroundColor: entry.color }}
          />
          <span className="tooltip-name">{entry.name}:</span>
          <span className="tooltip-value">
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              maximumFractionDigits: 2
            }).format(entry.value)}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomLegend = ({ payload }) => {
  if (!payload) return null;

  return (
    <div className="legend">
      {payload.map((entry, index) => (
        <div key={index} className="legend-entry">
          <div
            className="legend-color-dot"
            style={{ backgroundColor: entry.color }}
          />
          <span className="legend-label">
            {entry.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const DataVisualization = ({ data, headers, visualizationType }) => {
  if (!data || !headers || data.length === 0) {
    return (
      <div className="no-data">
        <p className="no-data-title">No data available for visualization</p>
        <p className="no-data-subtitle">Please provide data to generate the chart</p>
      </div>
    );
  }

  const transformData = (config) => {
    if (config?.type === 'groupedBar') {
      const groupedData = {};

      data.forEach((row) => {
        const xValue = row[headers.indexOf(config.xAxis)];
        const yValue = parseFloat(row[headers.indexOf(config.yAxis)]);
        const groupValue = row[headers.indexOf(config.groupBy)];

        if (!groupedData[xValue]) {
          groupedData[xValue] = { [config.xAxis]: xValue };
        }

        groupedData[xValue][groupValue] = yValue;
      });

      return Object.values(groupedData);
    }

    if (config?.stackBy) {
      const groupedData = {};

      data.forEach((row) => {
        const xValue = row[headers.indexOf(config.xAxis)];
        const yValue = parseFloat(row[headers.indexOf(config.yAxis)]);
        const stackValue = row[headers.indexOf(config.stackBy)];

        if (!groupedData[xValue]) {
          groupedData[xValue] = { [config.xAxis]: xValue };
        }

        if (!groupedData[xValue][stackValue]) {
          groupedData[xValue][stackValue] = 0;
        }

        groupedData[xValue][stackValue] += yValue;
      });

      return Object.values(groupedData);
    }

    return data.map((row) => {
      const obj = {};
      headers.forEach((header, index) => {
        const value = row[index];
        obj[header] = !isNaN(value) ? parseFloat(value) : value;
      });
      return obj;
    });
  };

  const renderPieChart = (config) => {
    const { value, category } = config;
    if (!value || !category) return null;

    const transformedData = transformData();

    return (
      <ResponsiveContainer width="80%" height={400}>
        <PieChart>
          <Pie
            data={transformedData}
            dataKey={value}
            nameKey={category}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={140}
            fill={COLORS.primary[0]}
            labelLine={false}
            animationDuration={CHART_STYLE.animation.duration}
            label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
          >
            {transformedData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS.primary[index % COLORS.primary.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderBarChart = (config) => {
    if (config.stackBy) {
    //   return renderStackedBarChart(config);
    }

    if (config.groupBy) {
      return renderGroupedBarChart(config);
    }

    const { xAxis, yAxis } = config;
    if (!xAxis || !yAxis) return null;

    const yAxisKey = Array.isArray(yAxis) ? yAxis[0] : yAxis;
    const transformedData = transformData();

    return (
      <ResponsiveContainer width="80%" height={400}>
        <BarChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis
            dataKey={xAxis}
            tick={{ fontSize: CHART_STYLE.fontSize }}
            angle={0}
          />
          <YAxis tick={{ fontSize: CHART_STYLE.fontSize }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          <Bar
            dataKey={yAxisKey}
            fill={COLORS.primary[0]}
            animationDuration={CHART_STYLE.animation.duration}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderGroupedBarChart = (config) => {
    const { xAxis, yAxis, groupBy } = config;
    if (!xAxis || !yAxis || !groupBy) return null;

    const transformedData = transformData(config);
    const groupValues = Array.from(new Set(data.map(row =>
      row[headers.indexOf(groupBy)]
    )));

    return (
      <ResponsiveContainer width="80%" height={400}>
        <BarChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis
            dataKey={xAxis}
            textAnchor="end"
            height={60}
            tick={{ fontSize: CHART_STYLE.fontSize }}
          />
          <YAxis tick={{ fontSize: CHART_STYLE.fontSize }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {groupValues.map((groupValue, index) => (
            <Bar
              key={groupValue}
              dataKey={groupValue}
              fill={COLORS.primary[index % COLORS.primary.length]}
              animationDuration={CHART_STYLE.animation.duration}
              radius={[4, 4, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderLineChart = (config) => {
    const { xAxis, yAxis } = config;
    if (!xAxis || !yAxis) return null;
  
    const yAxisKeys = Array.isArray(yAxis) ? yAxis : [yAxis];
    const transformedData = transformData(config);
  
    return (
      <ResponsiveContainer width="80%" height={400}>
        <LineChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis
            dataKey={xAxis}
            tick={{ fontSize: CHART_STYLE.fontSize }}
          />
          <YAxis tick={{ fontSize: CHART_STYLE.fontSize }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {yAxisKeys.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={COLORS.primary[index % COLORS.primary.length]}
              animationDuration={CHART_STYLE.animation.duration}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const renderStackedBarChart = (config) => {
    const { xAxis, yAxis, stackBy } = config;
    if (!xAxis || !yAxis || !stackBy) return null;
  
    const transformedData = transformData(config);
    const stackValues = Array.from(new Set(data.map(row =>
      row[headers.indexOf(stackBy)]
    )));
  
    return (
      <ResponsiveContainer width="80%" height={400}>
        <BarChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis
            dataKey={xAxis}
            textAnchor="end"
            height={60}
            tick={{ fontSize: CHART_STYLE.fontSize }}
          />
          <YAxis tick={{ fontSize: CHART_STYLE.fontSize }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {stackValues.map((stackValue, index) => (
            <Bar
              key={stackValue}
              dataKey={stackValue}
              stackId="stackedBar"
              fill={COLORS.primary[index % COLORS.primary.length]}
              animationDuration={CHART_STYLE.animation.duration}
              radius={[4, 4, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderStackedLineChart = (config) => {
    const { xAxis, yAxis, stackBy } = config;
    if (!xAxis || !yAxis || !stackBy) return null;
  
    const transformedData = transformData(config);
    const stackValues = Array.from(new Set(data.map(row =>
      row[headers.indexOf(stackBy)]
    )));
  
    return (
      <ResponsiveContainer width="80%" height={400}>
        <LineChart data={transformedData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
          <XAxis
            dataKey={xAxis}
            textAnchor="end"
            height={60}
            tick={{ fontSize: CHART_STYLE.fontSize }}
          />
          <YAxis tick={{ fontSize: CHART_STYLE.fontSize }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {stackValues.map((stackValue, index) => (
            <Line
              key={stackValue}
              type="monotone"
              dataKey={stackValue}
              stroke={COLORS.primary[index % COLORS.primary.length]}
              animationDuration={CHART_STYLE.animation.duration}
              strokeWidth={2}
              stackId="stackedLine"
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };
  
  
  

  const renderChart = () => {
    const { type, config } = visualizationType;

    switch (type.toLowerCase().replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()) {
      case 'pie':
        return renderPieChart(config);
      case 'bar':
        return renderBarChart(config);
      case 'line':
        return renderLineChart(config);
      case 'stacked-bar':
      case 'stackedbar':
        return renderStackedBarChart(config);
      case 'grouped-bar':
      case 'groupedbar':
        return renderGroupedBarChart({ ...config, type: 'groupedBar' });
      case 'stacked-line':
        return renderLineChart({ ...config, stacking: 'normal' });
      default:
        return (
          <div className="unsupported-type">
            Unsupported visualization type: {type}
          </div>
        );
    }
  };

  return (
    <div className="visualization-container">
      <div className="chart-wrapper">
        <h3 className="chart-title">
          {visualizationType.type
            .split(/(?=[A-Z])|[-]/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </h3>
        {renderChart()}
      </div>
    </div>
  );
};

export default DataVisualization;