import React, { useState, useEffect } from 'react';
import '../style/AddProjectDialog.css'; 
import MappingDialog from './MappingDialog';
import { AiOutlineClose,AiOutlineCaretLeft,AiOutlineCaretRight,AiOutlineStepForward} from 'react-icons/ai'; 
import { ImPrevious2 } from "react-icons/im";
import ProjectNameEntry from './AddProjectDialogComponents/ProjectNameEntry';
import SelectSource from './AddProjectDialogComponents/SelectSource';
import SelectSourceSystem from './AddProjectDialogComponents/SelectSourceSystem';
import SelectTarget from './AddProjectDialogComponents/SelectTarget';
import SelectTargetInput from './AddProjectDialogComponents/SelectTargetInput';
import Mapping from './AddProjectDialogComponents/Mapping';
import ProgressButtons from './AddProjectDialogComponents/ProgressButtons';
import toast, { Toaster } from 'react-hot-toast';

const AddProjectDialog = ({ isOpen, onClose, projectNameValidationData, onSave }) => {
  const [validationResult, setValidationResult] = useState(''); 
  const [sourceValidationResult, setSourceValidationResult] = useState('');
  const [targetValidationResult, setTargetValidationResult] = useState('');
  const [step, setStep] = useState(1);
  const [data, setData] = useState({ source: [], target: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [newProjectData, setNewProjectData] = useState({
    projectname: '',sourcesystem: '',servernames: '',databasenames: '',usernames: '',passwords: '',filepaths: '', hostnames: '', ports: '',protocols: '',dbqs: '',users: '',db_names: '',accountnames: '',accountkeys: '',containernames: '',auth_databases:'',excelfilenames:'',targetsystem: '',servernamet: '', databasenamet: '',usernamet: '',passwordt: '',filepatht: '', hostnamet: '', portt: '',protocolt: '',dbqt: '',usert: '', db_namet: '',accountnamet: '',accountkeyt: '',containernamet: '',auth_databaset:'',excelfilenamet:'',
  });
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
  const [srcConnectors, setSrcConnectors] = useState([]);
  const [tgtConnectors, setTgtConnectors] = useState([]);
  const [showSourceDialog, setShowSourceDialog] = useState(false);
  const [showTargetDialog, setShowTargetDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSqlDialog, setShowSqlDialog] = useState(false); 
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  console.log(loggedInUserEmail, 'is loggedinuseremail');
  const [selectedObjects, setSelectedObjects] = useState({});
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedTarget, setSelectedTarget] = useState('');
  const [validateButton, setValidateButton] = useState(false);
  const [sourceValidate, setSourceValidate] = useState(false);
  const [targetValidate, setTargetValidate] = useState(false);
  const [disableSourceOnValidate, setDisableSourceOnValidate] = useState(false);
  const [disableTargetOnValidate, setDisableTargetOnValidate] = useState(false);
  const [disableSaveAndRun, setDisableSaveAndRun] = useState(false);
  const [isAtLeastOneCheckboxSelected, setIsAtLeastOneCheckboxSelected] = useState(false);
  const [validated, setValidated] = useState(true);
  const [projectNameEntered, setProjectNameEntered] = useState(false);

  const LoadTypeOptions = [
    { value: 'direct', label: 'Direct' },
    { value: 'incremental', label: 'Incremental' },
  ];

  const defaultLoadTypeOption = LoadTypeOptions.find((option) => option.value === 'direct');
  const [selectedLoadTypeOption, setSelectedLoadTypeOption] = useState(defaultLoadTypeOption)


  const handleSelectAllDbObjects = () => {
    const allDbObjects = data.map(dbobject => dbobject.sourceobjects);
    setSelectedObjects(allDbObjects);
  };
  
  const handleCheckboxChange = (sourceObject) => {
    if(selectedObjects[sourceObject]){
      setSelectedObjects(prevState => {
        const copyValue = {...prevState}
        delete copyValue[sourceObject];
        
        return copyValue;})
    } else {
      setSelectedObjects(prevState => ({...prevState, [sourceObject]: 1}));
    }
  };

  const handleMoveToRight = () => {
    const newData = data.filter(dbobject => !selectedObjects.includes(dbobject.sourceobjects));
    setData(newData);
    setSelectedObjects([]);
  };

  const handleUncheckAndMoveToRight = () => {
    const updatedSelectedObjects = [...selectedObjects];
    for (let i = 0; i < updatedSelectedObjects.length; i++) {
      if (updatedSelectedObjects[i]) {
        updatedSelectedObjects.splice(i, 1);
        break; 
      }
    }
    setSelectedObjects(updatedSelectedObjects);
  };

  const handleDeselectAll = () => {
    setSelectedObjects([]);
  };
   
  const openMappingDialog = () => {
    setMappingDialogOpen(true);
    setButtonsDisabled(true); 
  };

  const handleSaveAndRun = () => {
    console.log('Triggered Save & Run func');
    console.log('Logged in user email is: ', loggedInUserEmail);
  
    const newProjectDataToSend = {
      user_email: loggedInUserEmail,
      name: newProjectData.projectname,
      source_configuration: {
        server_name: newProjectData.servernames,
        database_name: newProjectData.databasenames,
        user_name: newProjectData.usernames,
        password: newProjectData.passwords,
        excelfilename: newProjectData.excelfilenames,
        filepath: newProjectData.filepaths,
        host_name: newProjectData.hostnames,
        port: newProjectData.ports,
        protocol: newProjectData.protocols,
        account_name: newProjectData.accountnames,
        account_key: newProjectData.accountkeys,
        containername: newProjectData.containernames,
      },
      target_configuration: {
        server_name: newProjectData.servernamet,
        database_name: newProjectData.databasenamet,
        user_name: newProjectData.usernamet,
        password: newProjectData.passwordt,
        host_name: newProjectData.hostnamet,
        port: newProjectData.portt,
        protocol: newProjectData.protocolt,
      },
      db_objects_selected: selectedObjects,
      load_type: selectedLoadTypeOption.value,
  };
  
    console.log('Save New project:', newProjectDataToSend);
  
    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/new_project?source_system=${newProjectData.sourcesystem}&target_system=${newProjectData.targetsystem}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProjectDataToSend),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log('Save response data:', responseData); // Detailed logging
        if (responseData.status === 'success' && responseData.project_id) {
          console.log('Project saved successfully with ID:', responseData.project_id);
  
          const syncDataUrl = `${process.env.REACT_APP_FASTAPI_URL}/v1/data_sync/sync-data?project_id=${responseData.project_id}`;
          console.log('Sync Data URL:', syncDataUrl);
  
          fetch(syncDataUrl, {
            method: 'GET',  
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => response.json())
            .then((responseData) => {
              console.log('Data sync response:', responseData);
              if (responseData.status === 'success') {
                console.log('New project executed successfully!');
                onClose();
              } else {
                console.error('Error executing new project:', responseData.message);
              }
            })
            .catch((error) => {
              console.error('Error executing new project:', error);
            });
        } else {
          console.error('Error saving project:', responseData.message);
        }
      })
      .catch((error) => {
        console.error('Error saving project:', error);
      });
  };


  const handleSave = () => {
    console.log('Triggered Save func')
    // const concatenatedSelectedObjects = selectedObjects.join(', ');
    const newProjectDataToSend = {
      user_email: loggedInUserEmail,
      name: newProjectData.projectname,
      source_configuration: {
        server_name: newProjectData.servernames,
        database_name: newProjectData.databasenames,
        user_name: newProjectData.usernames,
        password: newProjectData.passwords,
        excelfilename: newProjectData.excelfilenames,
        filepath: newProjectData.filepaths,
        host_name: newProjectData.hostnames,
        port: newProjectData.ports,
        protocol: newProjectData.protocols,
        account_name: newProjectData.accountnames,
        account_key: newProjectData.accountkeys,
        containername: newProjectData.containernames,
      },
      target_configuration: {
        server_name: newProjectData.servernamet,
        database_name: newProjectData.databasenamet,
        user_name: newProjectData.usernamet,
        password: newProjectData.passwordt,
        host_name: newProjectData.hostnamet,
        port: newProjectData.portt,
        protocol: newProjectData.protocolt,
      },
      db_objects_selected: selectedObjects,
      load_type: selectedLoadTypeOption.value,
    };
  
    console.log('Save New project:', newProjectDataToSend);

    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/new_project?source_system=${newProjectData.sourcesystem}&target_system=${newProjectData.targetsystem}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProjectDataToSend),
    })
    .then((response) => response.json())
    .then((responseData) => {
      if (responseData.status === 'success') {
        console.log('Project saved successfully!');
        onClose();
      } else {
        console.error('Error saving project:', responseData.message);
      }
    })
    .catch((error) => {
      console.error('Error saving project:', error);
    });  
  };

  const srcFilteredConnectors = srcConnectors.filter(
    (connector) => {
      if (connector.name){
        connector.name.toLowerCase().includes(searchTerm.toLowerCase())
      }}
  );

  const tgtFilteredConnectors = tgtConnectors.filter(
    (connector) => {
      if (connector.name){
        connector.name.toLowerCase().includes(searchTerm.toLowerCase())
      }}
  );

  const handleValidate = (validationType) => {
    const newValidateDataToSend = {
      useremail:loggedInUserEmail,
      projectname: newProjectData.projectname,
      sourcesystem:newProjectData.sourcesystem,
      servernames:newProjectData.servernames,
      databasenames:newProjectData.databasenames,
      usernames:newProjectData.usernames,
      passwords:newProjectData.passwords,
      filepaths:newProjectData.filepaths,
      excelfilenames:newProjectData.excelfilenames,
      hostnames:newProjectData.hostnames,
      ports:newProjectData.ports,
      protocols:newProjectData.protocols,
      dbqs:newProjectData.dbqs,
      users:newProjectData.users,
      db_names:newProjectData.db_names,
      containernames:newProjectData.containernames,
      accountkeys:newProjectData.accountkeys,
      accountnames:newProjectData.accountnames,
      auth_databases:newProjectData.auth_databases,
      targetsystem:newProjectData.targetsystem,
      servernamet:newProjectData.servernamet,
      databasenamet:newProjectData.databasenamet,
      usernamet:newProjectData.usernamet,
      passwordt:newProjectData.passwordt,
      filepatht:newProjectData.filepatht,
      hostnamet:newProjectData.hostnamet,
      portt:newProjectData.portt,
      protocolt:newProjectData.protocolt,
      dbqt:newProjectData.dbqt,
      usert:newProjectData.usert,
      db_namet:newProjectData.db_namet,
      containernamet:newProjectData.containernamet,
      accountkeyt:newProjectData.accountkeyt,
      accountnamet:newProjectData.accountnamet,
      auth_databaset:newProjectData.auth_databaset,
      excelfilenamet:newProjectData.excelfilenamet,
    };

    console.log('handleValidate:', newValidateDataToSend);
    fetch(process.env.REACT_APP_FASTAPI_URL+'/v1/connectors/validate-connector/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newValidateDataToSend),
    })
    .then(response => {
      console.log('Raw Response:', response);
      return response.json();
    })

    .then((responseData) => {
      if (responseData.status === 'success') {
        console.log('Project validated successfully!');
        if(validationType==='source'){ setSourceValidationResult('Valid configuration'); }
        else {
          // setDisableTargetOnValidate(true); 
          setTargetValidationResult('Valid configuration')}
        setValidationResult('Valid configuration');
        // setDisableSourceOnValidate(true);
        setButtonsDisabled(false);
      } else {
        console.log('Project validated Error!');
        toast.error('Invalid configuration. Try again');
        if(validationType==='source'){ 
          setSourceValidationResult('Invalid configuration');
          setButtonsDisabled(true);
        }
        else {
          setTargetValidationResult('Invalid configuration');
          setButtonsDisabled(true);
        }
        setValidationResult('Invalid configuration');
        
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const fetchConnectors = () => {
    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/connectors`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('Connectors response from backend:', result);
        if (result.status === "success" && result.data) {
          const connectors = result.data;
          if (connectors.source) {
            setSrcConnectors(connectors.source);
          }
          if (connectors.target) {
            setTgtConnectors(connectors.target);
          }
        } else {
          console.error('Unexpected response structure:', result);
        }
      })
      .catch((error) => console.error('Error fetching connectors:', error));
  };

  useEffect(() => {
    fetchConnectors();
  }, [loggedInUserEmail]);

  const handleCancel = () => {
    onClose();
  };

const servername = newProjectData.servernames;
const database = newProjectData.databasenames;
const username = newProjectData.usernames;
const password = newProjectData.passwords;

useEffect(() => {
  if (newProjectData.sourcesystem === 'MS SQL Server' && newProjectData.servernames && newProjectData.databasenames && newProjectData.usernames && newProjectData.passwords && newProjectData.ports) {
    const dbObjectRequest = {
      "server": newProjectData.servernames,
      "database": newProjectData.databasenames,
      "username": newProjectData.usernames,
      "password": newProjectData.passwords,
      "port": newProjectData.ports,
      "db_type": newProjectData.sourcesystem,
    };
    const fetchdbobjects = () => {
      fetch(process.env.REACT_APP_FASTAPI_URL+`/v1/dbobjects/read-dbobjects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dbObjectRequest),
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('dbobjects received from backend for MS SQL Server:', dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchdbobjects();
  }
}, [newProjectData.sourcesystem, newProjectData.servernames, newProjectData.databasenames, newProjectData.usernames, newProjectData.passwords]);

useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure SQL Server') {
    const servername = newProjectData.servernames;
    const database = newProjectData.databasenames;
    const username = newProjectData.usernames;
    const password = newProjectData.passwords;

    const fetchdbobjects = () => {
      console.log('servername:', servername);
      console.log('database:', database);
      console.log('username:', username);
      
      fetch(process.env.REACT_APP_API_URL+`azuredbobjects?server=${servername}&database=${database}&username=${username}&password=${password}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('dbobjects received from backend:', dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchdbobjects();
  }
}, [newProjectData.sourcesystem, newProjectData.servernames, newProjectData.databasenames, newProjectData.usernames, newProjectData.passwords]);

useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure Data Lake Table Storage') {
    const accountname = newProjectData.accountnames;
    const accountkey = newProjectData.accountkeys;

    const datalaketableobject = () => {
      const encodedAccountName = encodeURIComponent(accountname);
      const encodedAccountKey = encodeURIComponent(accountkey);
      fetch(process.env.REACT_APP_API_URL+`datalaketables?accountname=${encodedAccountName}&accountkey=${encodedAccountKey}`)
        .then((response) => response.json())
        .then((dbobjects) => {
          console.log('accountname:', accountname);
          console.log('accountkey:', accountkey);
          console.log('datalaketableobjects received from backend:', dbobjects);
          setData(dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };

    datalaketableobject();
  }
}, [newProjectData.sourcesystem, newProjectData.accountnames, newProjectData.accountkeys]);


/* LOCAL EXCEL */
useEffect(() => {
  if (newProjectData.sourcesystem === 'EXCEL') {
    const filepath = newProjectData.filepaths;

    const fetchexcelData = () => {
      fetch(process.env.REACT_APP_API_URL+`excelobjects?filepath=${filepath}`,{
        method: 'GET',
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          console.log('Excel objects received from backend:', dbobjects);
          setData(dbobjects);
      })
        .catch((error) => console.error('Error fetching Excel data:', error));
    };
    fetchexcelData();
  }
}, [newProjectData.sourcesystem,newProjectData.filepaths]);

/* Flat files */
useEffect(() => {
    if (newProjectData.sourcesystem === 'Text/Csv') {
      const filepath = newProjectData.filepaths;
  
      const fetchflatfilesData = () => {
        fetch(process.env.REACT_APP_API_URL+`fetchflatfiles?filepath=${filepath}`,{
          method: 'GET',
        })
          .then((response) => response.json())
          .then((dbobjects) => {
            setData(dbobjects);
            console.log('Flat file objects received from backend:', dbobjects);
        })
          .catch((error) => console.error('Error fetching Excel data:', error));
      };
      fetchflatfilesData();
    }
  }, [newProjectData.sourcesystem,newProjectData.filepaths]);
  

/*  Blob Flatfiles */
useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure Blob Storage Flatfiles') {
    const accountname = newProjectData.accountnames;
    const accountkey = newProjectData.accountkeys;
    const containername = newProjectData.containernames;

    const fetchblobflatfiles = () => {
      const encodedAccountName = encodeURIComponent(accountname);
      const encodedAccountKey = encodeURIComponent(accountkey);
      const encodedcontainername = encodeURIComponent(containername);
      fetch(process.env.REACT_APP_API_URL+`blobflatfiles?accountname=${encodedAccountName}&accountkey=${encodedAccountKey}&containername=${encodedcontainername}`)
        .then((response) => response.json())
        .then((dbobjects) => {
          console.log('accountname:', accountname);
          console.log('accountkey:', accountkey);
          console.log('containername:', containername);
          console.log('blob flat file objects received from backend:', dbobjects);
          setData(dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };

    fetchblobflatfiles();
  }
}, [newProjectData.sourcesystem, newProjectData.accountnames, newProjectData.accountkeys,newProjectData.containernames]);


/* Bob excel files */
useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure Blob Storage Excel') {
    const accountname = newProjectData.accountnames;
    const accountkey = newProjectData.accountkeys;
    const containername = newProjectData.containernames;
    const excelfilename = newProjectData.excelfilenames

    const fetchblobexcelfiles = () => {
      const encodedAccountName = encodeURIComponent(accountname);
      const encodedAccountKey = encodeURIComponent(accountkey);
      const encodedcontainername = encodeURIComponent(containername);
      const encodedexcelfilename = encodeURIComponent(excelfilename);
      fetch(process.env.REACT_APP_API_URL+`blobexcelfileobjects?accountname=${encodedAccountName}&accountkey=${encodedAccountKey}&containername=${encodedcontainername}&excelfilename=${encodedexcelfilename}`)
        .then((response) => response.json())
        .then((dbobjects) => {
          console.log('accountname:', accountname);
          console.log('accountkey:', accountkey);
          console.log('containername:', containername);
          console.log('excelfilename', excelfilename);
          console.log('blob excel file objects received from backend:', dbobjects);
          setData(dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };

    fetchblobexcelfiles();
  }
}, [newProjectData.sourcesystem, newProjectData.accountnames, newProjectData.accountkeys,newProjectData.containernames,newProjectData.excelfilenames]);


/* MYSQL */
useEffect(() => {
  if (newProjectData.sourcesystem === 'MySQL' && newProjectData.hostnames && newProjectData.usernames 
  && newProjectData.passwords && newProjectData.databasenames) {
    const dbObjectRequest = {
      "server": newProjectData.hostnames,
      "database": newProjectData.databasenames,
      "username": newProjectData.usernames,
      "password": newProjectData.passwords,
      "port": newProjectData.ports,
      "db_type": newProjectData.sourcesystem,
    };
    const fetchdbobjects = () => {
      fetch(process.env.REACT_APP_FASTAPI_URL+`/v1/dbobjects/read-dbobjects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dbObjectRequest),
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('dbobjects received from backend for MySQL:', dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchdbobjects();
  }
}, [newProjectData.sourcesystem, newProjectData.hostnames,newProjectData.usernames, newProjectData.passwords, newProjectData.databasenames]);

/* Azure Mysql db */
useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure MySQL') {
    const hostname = newProjectData.hostnames; // Ensure special characters are properly encoded
    const username = newProjectData.usernames;
    const password = newProjectData.passwords;
    const database = newProjectData.databasenames;
    console.log('hostname:', hostname);
    console.log('username:', username);
    console.log('database:', database);
    const fetchazureMySQLData = () => {
      fetch(process.env.REACT_APP_API_URL+`azuremysqldbobjects?hostname=${hostname}&username=${username}&password=${password}&database=${database}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',      
        },        
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('Azure MySQL database objects received from backend:', dbobjects);
      })
        .catch((error) => console.error('Error fetching Azure MySQL data:', error));
    };
    fetchazureMySQLData();
  }
}, [newProjectData.sourcesystem, newProjectData.hostnames,newProjectData.usernames, newProjectData.passwords, newProjectData.databasenames]);

/* postgres sql*/
useEffect(() => {
  if (newProjectData.sourcesystem === 'PostgreSQL') {
    const dbObjectRequest = {
      "server": newProjectData.servernames,
      "database": newProjectData.databasenames,
      "username": newProjectData.usernames,
      "password": newProjectData.passwords,
      "port": newProjectData.ports,
      "db_type": newProjectData.sourcesystem,
    };
    const fetchdbobjects = () => {
      fetch(process.env.REACT_APP_FASTAPI_URL+`/v1/dbobjects/read-dbobjects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dbObjectRequest),
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('dbobjects received from backend for Postgres:', dbobjects);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchdbobjects();
  }
}, [newProjectData.sourcesystem, newProjectData.hostnames,newProjectData.ports, newProjectData.databasenames, newProjectData.usernames, newProjectData.passwords]);

/* Azure postgres sql*/
useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure PostgreSQL') {
    const hostname = newProjectData.hostnames;
    const port= newProjectData.ports;
    const username = newProjectData.usernames;
    const database = newProjectData.databasenames;
    const password = newProjectData.passwords;
    const fetchazurepostgresbobjects = () => {
      console.log('hostname:', hostname);
      console.log('port:', port);
      console.log('username:', username);
      console.log('database:', database);
      console.log('password:', password);

      fetch(process.env.REACT_APP_API_URL+`azurepostgresqldbobjects?hostname=${hostname}&port=${port}&username=${username}&database=${database}&password=${password}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('Azure postgresqldbobjects received from backend:', dbobjects);
      })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchazurepostgresbobjects();
  }
}, [newProjectData.sourcesystem, newProjectData.hostnames,newProjectData.ports, newProjectData.databasenames, newProjectData.usernames, newProjectData.passwords]);

/* mongodb */
useEffect(() => {
  if (newProjectData.sourcesystem === 'MongoDB') {
    const hostname = newProjectData.hostnames;
    const port= newProjectData.ports
    const username = newProjectData.usernames;
    const password = newProjectData.passwords;
    const auth_database = newProjectData.auth_databases;
    const databasename = newProjectData.databasenames;
    const fetchmongodbcollections = () => {
      console.log('hostname:', hostname);
      console.log('port:', port);
      console.log('username:', username);
      console.log('password:', password);
      console.log('auth_database:', auth_database);
      console.log('databasename:', databasename);

      fetch(process.env.REACT_APP_API_URL+`mongodbdcollections?hostname=${hostname}&port=${port}&username=${username}&password=${password}&auth_database=${auth_database}&databasename=${databasename}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((dbobjects) => {
          setData(dbobjects);
          console.log('mongodbcollections received from backend:', dbobjects);
      })
        .catch((error) => console.error('Error fetching data:', error));
    };
    fetchmongodbcollections();
  }
}, [newProjectData.sourcesystem, newProjectData.hostnames,newProjectData.ports, newProjectData.usernames, newProjectData.passwords,  newProjectData.auth_databases,newProjectData.databasenames]);


const azurecosmosmongocollections = () => {
  fetch(process.env.REACT_APP_API_URL+'azurecosmosmongocollections')
    .then((azurecosmosmongocollections) => azurecosmosmongocollections.json())
    .then((azurecosmosmongocollections) => {
      console.log('azurecosmosmongocollections received from backend:', azurecosmosmongocollections);
      setData(azurecosmosmongocollections);
    })
    .catch((error) => console.error('Error fetching data:', error));
};

useEffect(() => {
  if (newProjectData.sourcesystem === 'Azure cosmosDB') {
    azurecosmosmongocollections();
  }
}, [newProjectData.sourcesystem]);


  const handleSourceSelect = (selectedSource) => {
    setSelectedSource(selectedSource);
    setNewProjectData((prevData) => ({
      ...prevData,  
      sourcesystem: selectedSource,
    }));
    setShowSourceDialog(true);
  };

  const handleTargetSelect = (selectedTarget) => {
    setSelectedTarget(selectedTarget);
    setNewProjectData((prevData) => ({
      ...prevData,
      targetsystem: selectedTarget,
    }));
    setShowTargetDialog(true);
  };
 
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setSourceValidate(false);
    if(setShowSourceDialog===true){
      setButtonsDisabled(true);
    }
    if(setShowTargetDialog===true){
      setButtonsDisabled(true);
    }
    setSourceValidationResult('Invalid configuration');
    setTargetValidationResult('Invalid configuration');
    setNewProjectData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const filePath = URL.createObjectURL(selectedFile);
      setSelectedFile(selectedFile); // Store the selected file object
      setNewProjectData({
        ...newProjectData,
        filepaths: filePath,
      });
    }
  };

  const handleNext = () => {
    if (step === 1) {
      if (newProjectData.projectname.trim() === '') {
        toast.error('Please enter the project name.');
        return;
      }
      const isProjectNameValid = () => {
        return projectNameValidationData.includes(newProjectData.projectname);
      };
      if (isProjectNameValid()) {
        toast.error('Project name already exists. Please choose a different name.');
        return;
    }
    setProjectNameEntered(true);
    } else if (step === 2) {
      if (!newProjectData || !newProjectData.selectedsource || newProjectData.selectedsource.trim() === '') {
        toast.error('Please select a source.');
        return;
      }
     
    } else if (step === 3) {

    } else if (step === 4) {
      if (!newProjectData || !newProjectData.targetsystem || newProjectData.targetsystem.trim() === '') {
        toast.error('Please select a target.');
        return;
      }
   
    } else if (step === 5) {
      if (newProjectData.targetsystem.includes('SQL Server')) {
        if (newProjectData.servernamet.trim() === '' || newProjectData.databasenamet.trim() === '' || newProjectData.usernamet.trim() === '' || newProjectData.passwordt.trim() === '') {
          alert('Please fill in all SQL target fields.');
          return;
        }
      } else if (newProjectData.targetsystem.includes('EXCEL')) {
        if (newProjectData.filepatht.trim() === '') {
          alert('Please enter the filepath for the Excel target.');
          return;
        }
      } 
    } else if (step === 6) {
      if(!isAtLeastOneCheckboxSelected){
        toast.error('Select at least one option');
        return;
      }
      handleSave();
    }
    else if (step === 7) {

    }
    if (step < 7) {
      setStep(step + 1);
    }
  };
 
  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (newProjectData.sourcesystem) {
      setShowSourceDialog(
        newProjectData.sourcesystem === 'MS SQL Server'||
        newProjectData.sourcesystem === 'Azure SQL Server' ||
        newProjectData.sourcesystem === 'Azure Data Lake Table Storage' || 
        newProjectData.sourcesystem === 'Azure Blob Storage Flatfiles' || 
        newProjectData.sourcesystem === 'Azure Blob Storage Excel' ||
        newProjectData.sourcesystem === 'MongoDB'||
        newProjectData.sourcesystem === 'Azure CosmosDB'||
        newProjectData.sourcesystem === 'EXCEL' ||
        newProjectData.sourcesystem === 'Flatfile' ||
        newProjectData.sourcesystem === 'MySQL' ||
        newProjectData.sourcesystem === 'Azure PostgreSQL' ||
        newProjectData.sourcesystem === 'PostgreSQL' ||
        newProjectData.sourcesystem === 'Access' ||
        newProjectData.sourcesystem === 'Azure MySQL' ||
        newProjectData.sourcesystem === 'Teradata' ||
        newProjectData.sourcesystem === 'SAP HANA' ||
        newProjectData.sourcesystem === 'Text/Csv'
      );  
      setStep(3);
    }
  }, [newProjectData.sourcesystem]);


  useEffect(() => {
    if (newProjectData.targetsystem) {
      setShowTargetDialog(
        newProjectData.targetsystem === 'MS SQL Server' ||
        newProjectData.targetsystem === 'Azure SQL Server' ||  
        newProjectData.targetsystem === 'Azure Data Lake Table Storage' || 
        newProjectData.targetsystem === 'Azure Blob Storage Flatfiles'|| 
        newProjectData.targetsystem === 'Azure Blob Storage Excel'|| 
        newProjectData.targetsystem === 'MongoDB'|| 
        newProjectData.targetsystem === 'Azure CosmosDB'||
        newProjectData.targetsystem === 'EXCEL' || 
        newProjectData.targetsystem === 'Flatfile' ||
        newProjectData.targetsystem === 'MySQL' ||
        newProjectData.targetsystem === 'Azure PostgreSQL' ||
        newProjectData.targetsystem === 'PostgreSQL' ||
        newProjectData.targetsystem === 'Access' ||
        newProjectData.targetsystem === 'Azure MySQL' ||
        newProjectData.targetsystem === 'Teradata' ||
        newProjectData.targetsystem === 'SAP HANA' ||
        newProjectData.targetsystem === 'Text/Csv'
      );  
      setStep(5);
    }
  }, [newProjectData.targetsystem]);

  const handleExecutionClick = async () => {
    if(!isAtLeastOneCheckboxSelected){
      toast.error('Select at least one option');
      return;
    }
    console.log('Vase & Run 1')
    await openMappingDialog();
    console.log('Vase & Run 2')
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(10000);
    console.log('Vase & Run 3')
    handleSaveAndRun();
  };

  return (
    <div className={`dialog-container ${isOpen ? 'open' : ''}`}>
    <div className="dialog-content flex flex-col">
      <div className='dialog-content-inner-border'>
        <button className="close-button bg-gray-500 text-white px-4 py-1 rounded hover:bg-gray-700" onClick={onClose}>
                <AiOutlineClose />
        </button>
        {projectNameEntered && selectedLoadTypeOption? (
          <h1 className="dialog-heading text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mr-4">
            {`${newProjectData.projectname}-${selectedLoadTypeOption.label} Load Type`}
          </h1>
        ) : (
          <h1 className="dialog-heading text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mr-4">
            NEW PROJECT
          </h1>
        )}

        <div className="progress-section bg-gray-300 overflow-hidden">
          <ProgressButtons step={step} />
        </div>

      <div className="step-content-section">
        {step === 1 && (
        <ProjectNameEntry       
            newProjectData={newProjectData}
            handleInputChange={handleInputChange}
            setDisableSaveAndRun={setDisableSaveAndRun}
            selectedLoadTypeOption={selectedLoadTypeOption} 
            setSelectedLoadTypeOption={setSelectedLoadTypeOption} 
            LoadTypeOptions={LoadTypeOptions}
        />)}

        {step === 2 && ( 
        <SelectSource   
            connectors={srcConnectors}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredConnectors={srcFilteredConnectors}
            handleSourceSelect = {handleSourceSelect}
            setSourceValidate = {setSourceValidate}
            setTargetValidate = {setTargetValidate}
            setButtonsDisabled = {setButtonsDisabled}
            setDisableSaveAndRun={setDisableSaveAndRun}
        />)}
  
        {step === 3 && (<SelectSourceSystem   
          showSourceDialog={showSourceDialog}
          newProjectData={newProjectData}
          handleInputChange={handleInputChange}
          selectedFile={selectedFile}
          handleValidate={handleValidate}
          sourceValidationResult={sourceValidationResult}
          selectedSource={selectedSource}
          setValidateButton={setValidateButton}
          setSourceValidate={setSourceValidate}
          setButtonsDisabled={setButtonsDisabled} 
          disableSourceOnValidate = {disableSourceOnValidate}      
          setDisableSaveAndRun={setDisableSaveAndRun}   
          />)}
 
        {step === 4 && (
          <SelectTarget   
            connectors={tgtConnectors}
            filteredConnectors={tgtFilteredConnectors}
            handleTargetSelect={handleTargetSelect}
            setSourceValidate = {setSourceValidate}
            setTargetValidate = {setTargetValidate}
            setButtonsDisabled={setButtonsDisabled} 
            setDisableSaveAndRun={setDisableSaveAndRun}
          />)}
 
        {step === 5 && (
          <SelectTargetInput 
            showTargetDialog={showTargetDialog}
            selectedTarget={selectedTarget}
            showSqlDialog={showSqlDialog}
            newProjectData={newProjectData}
            handleInputChange={handleInputChange}
            selectedFile={selectedFile}
            handleValidate={handleValidate}
            targetValidationResult={targetValidationResult}
            setValidateButton={setValidateButton}
            setTargetValidate = {setTargetValidate}
            setButtonsDisabled={setButtonsDisabled} 
            disableTargetOnValidate={disableTargetOnValidate} 
            setDisableSaveAndRun={setDisableSaveAndRun}
            />)}

        {/* {validationResult && (
                <div className="validation-result">
                  {validationResult.source && <div>Source Validation Result: {validationResult.source}</div>}
                  {validationResult.target && <div>Target Validation Result: {validationResult.target}</div>}
                </div>
              )} */}

          {step === 6 && (
            <div className='mapping-container'>
            <Mapping 
            data={data}
            selectedObjects={selectedObjects}
            handleCheckboxChange={handleCheckboxChange}
            handleUncheckAndMoveToRight={handleUncheckAndMoveToRight}
            AiOutlineCaretLeft={AiOutlineCaretLeft}
            handleSelectAllDbObjects={handleSelectAllDbObjects}
            AiOutlineStepForward={AiOutlineStepForward}
            handleMoveToRight={handleMoveToRight}
            AiOutlineCaretRight={AiOutlineCaretRight}
            handleDeselectAll={handleDeselectAll}
            ImPrevious2={ImPrevious2}
            handleExecutionClick={handleExecutionClick}
            mappingDialogOpen={mappingDialogOpen}
            MappingDialog={MappingDialog}
            setMappingDialogOpen={setMappingDialogOpen}
            setSourceValidate = {setSourceValidate}
            setTargetValidate = {setTargetValidate}
            setDisableSaveAndRun={setDisableSaveAndRun}
            setIsAtLeastOneCheckboxSelected={setIsAtLeastOneCheckboxSelected}
            selectedLoadTypeOption={selectedLoadTypeOption} 
            setSelectedLoadTypeOption={setSelectedLoadTypeOption} 
            LoadTypeOptions={LoadTypeOptions}
            setSelectedObjects = {setSelectedObjects}
            />
            </div>)}


          {step === 7 && (
            <h1>NEW PROJECT IS CREATED</h1>
          )}
        </div>
        <div className='buttons-container'>
            <button className="buttonMain buttonColorSave" onClick={handleNext} disabled={buttonsDisabled}>Save &#8594;</button>
            {validateButton && sourceValidate && (
                <button
                  className={`buttonMain buttonValidate${sourceValidationResult === 'Valid configuration' ? ' validated' : ''}`}
                  onClick={(e) => {
                    const validationType='source';
                    handleValidate(validationType)}}
                  disabled={sourceValidationResult === 'Valid configuration'} 
                  >
                  {sourceValidationResult === 'Valid configuration' ? (
                      <>
                        Validated <span className="checkmark">&#10003;</span>
                      </>
                    ) : 'Validate'
                  }
                </button>
            )}
            {validateButton && targetValidate && (
                <button
                  className={`buttonMain buttonValidate${targetValidationResult === 'Valid configuration' ? ' validated' : ''}`}
                  onClick={(e) => {
                    const validationType='target';
                    handleValidate(validationType)}}
                  disabled={targetValidationResult === 'Valid configuration'} 
                  >
                  {targetValidationResult === 'Valid configuration' ? (
                      <>
                        Validated <span className="checkmark">&#10003;</span>
                      </>
                    ) : 'Validate'
                  }
                </button>
            )}
            {disableSaveAndRun && 
            (
              <div>
                <button className="buttonMain buttonSaveAndRun" onClick={() => handleExecutionClick()}>
                Save & Run
                </button>
                {mappingDialogOpen && <MappingDialog onClose={() => setMappingDialogOpen(false)} />}
              </div>
            )}
            <button className="buttonMain buttonColorSaveAsDraft" onClick={handlePrevious}>Save as draft </button>
            
        </div>
        </div>

      </div>
      <Toaster />
    </div>
  );
};

export default AddProjectDialog;