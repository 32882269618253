import { useState, useEffect } from "react";

const ConfigureTargetSystem = ({ formData, setFormData, handlePrevious, connectors, handleNext, setData }) => {
 
    const [selectedTarget, setSelectedTarget] = useState(''); 
    const [targetConfigFormData, setTargetConfigFormData] = useState({});  
    const [validationStatus, setValidationStatus] = useState(false); 
    const [validationMessage, setValidationMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false); 

    const configRequirements = {
        "MS SQL Server": {
            "Server": "servernamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "MongoDB": {
            "Hostname": "servernamet",
            "Port": "portt",
            "AuthDatabase": "databasenamet",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "MySQL": {
            "Hostname": "hostnamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "Azure MySQL": {
            "Hostname": "hostnamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "PostgreSQL": {
            "Hostname": "servernamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Schema": "schema",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "Azure PostgreSQL": {
            "Hostname": "hostnamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Schema": "schema",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "Azure SQL Server": {
            "Hostname": "hostnamet",
            "Port": "portt",
            "Database": "databasenamet",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "Azure CosmosDB": {
            "Hostname": "hostnamet",
            "Port": "portt",
            "Primary Key": "primary_key",
            "Username": "usernamet",
            "Password": "passwordt"
        },
        "EXCEL": {
            "File Path": "filepatht"
        },
        "Azure Blob Storage Flatfiles": {
            "AccountName": "accountnamet",
            "Account Key": "accountkeyt",
            "Container Name": "containernamet",
        },
        "Azure Data Lake Table Storage": {
            "Account Name": "accountnamet",
            "Account Key": "accountkeyt",
            "Container Name": "containernamet",
        },
        "Azure Blob Storage Excel": {
            "Account Name": "accountnamet",
            "Account Key": "accountkeyt",
            "Container Name": "containernamet",
            "Excel File Name": "excelfilenamet",
        },
        "Azure Blob Storage": {
            "AccountName": "accountnamet",
            "AccountKey": "accountkeyt",
            "ContainerName": "containernamet",
            "Excel File Name": "excelfilenamet",
        },
        "Text/CSV": {
            "File Path": "filepatht"
        }
    };

    // Updates target system on change and resets form data except for the sourcesystem key
    const handleTargetChange = (target) => {
        console.log("Form Data after selecting Target:", formData);
        console.log("Selected target:", target);
        setSelectedTarget(target);
        console.log("Selected target:", selectedTarget);
        setTargetConfigFormData({ targetsystem: target });
        setValidationStatus(false);  // Resets validation status
        setValidationMessage("");
    };

    // This ensures the 'targetsystem' field stays updated whenever the target is selected or changed
    // useEffect(() => {
    //     if (selectedTarget) {
    //         setTargetConfigFormData((prev) => ({
    //             ...prev,
    //             targetsystem: selectedTarget
    //         }));
    //     }
    // }, [selectedTarget]);

    const handleValidation = async () => {
        setValidationMessage("");
        setValidationStatus(false); 
        setIsLoading(true);
    
        try {
            const validationResponse = await fetch(
                `${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/validate-connector`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(targetConfigFormData),
                }
            );    
            const validationData = await validationResponse.json();
    
            if (validationData.status === "success") {
                setValidationStatus(true);
                setValidationMessage(validationData.message);
                setFormData({ ...formData, ...targetConfigFormData });
                console.log("Form Data after Target Validation:", formData);

                handleNext();
            } else {
                setValidationStatus(false);
                setValidationMessage(validationData.message);
            }
        } catch (error) {
            setValidationStatus(false);
            setValidationMessage("An error occurred during validation.");
        } finally {
            setIsLoading(false); 
        }
    };

    const renderFields = () => {
        const fields = configRequirements[selectedTarget];
        if (!fields) return <span className="config-details-missing">Configuration details for {selectedTarget} are missing.</span>;

        return Object.entries(fields).map(([label, key]) => (
            <div key={key} className="form-field">
                <label className="label-sql-add">{label}</label>
                <input
                    type={label.toLowerCase() === "password" ? "password" : "text"}
                    value={targetConfigFormData[key] || ""}
                    className="input-sql-add"
                    onChange={(e) =>
                        setTargetConfigFormData({ ...targetConfigFormData, [key]: e.target.value })
                    }
                    placeholder={`Enter ${label.toLowerCase()}`}
                />
            </div>
        ));
    };

    return (
        <div className="form-page-card-inner-layout">
            <div className="form-page-card-source-target-selection">
                {!selectedTarget ? (
                    <div className="source-tiles scrollable-box-sql-project-details">
                        {connectors.target.map((connector) => (
                            <div
                                key={connector.value}
                                className="source-tile"
                                onClick={() => handleTargetChange(connector.name)}
                            >
                                <div className="source-target-label">{connector.name}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <h3>{selectedTarget} Configuration</h3>
                        <div className="form-two-columns">
                            {renderFields()}
                        </div>
                        <div className="navigation-buttons">
                            <button onClick={() => setSelectedTarget("")}>Back to Targets</button>
                            {isLoading ? (
                                <button disabled>
                                    Validating...
                                    <span className="loader"></span> 
                                </button>
                            ) : (
                                <button onClick={handleValidation}>Validate</button>
                            )}
                        </div>
                        {validationMessage && <p>{validationMessage}</p>}
                    </>
                )}
            </div>
        </div>
    );
};

export default ConfigureTargetSystem;