import React, { useState, useEffect, useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import BreadcrumbItem from "../Breadcrumbs";
import { useLocation  } from 'react-router-dom';
import SearchFilterSort from '../SearchFilterSort';
// import '../../style/ProjectStatus.css';

const RunHistoryDMEntityLevel = () => { 
    const [filter, setFilter] = useState("All"); 
    const [sortBy, setSortBy] = useState({ field: 'Creation Date', isAscending: true });
    const [data, setData] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
    const location = useLocation();
    const { projectData } = location.state || {};

    const projectid = projectData?.projectid;

    console.log("projectdetails in RunHistoryDMEntityLevel: ", projectData, projectid);

    useEffect(() => {
        if (projectid) {
            const fetchProjects = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/data-migration/manual-entitylevel-status?email=${loggedInUserEmail}&project_id=${projectid}`);
                    const projects = await response.json();
                    setData(projects.data);
                    console.log('DM Projects Entity level data from endpoint: ', data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchProjects();
        }
    }, [loggedInUserEmail, projectid]);

    const handleFilterChange = (e) => setFilter(e.target.value);

    const handleSortByChange = (e) => {
        const selectedField = e.target.value;
        setSortBy(prev => ({
            field: selectedField,
            isAscending: prev.field === selectedField ? !prev.isAscending : true,
        }));
    };

    const handleFilter = useCallback((filteredData) => {
        setFilteredProjects(filteredData);
    }, []);

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                    <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/run-history-project-status" label="Run History Project Status" isActive={false} />
                        <BreadcrumbItem to="/dm-object-details" label="DM Entity Level Status" isActive={true} />
                    </ul>
                </nav>
                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">Run History DM Entity Level Status</h4>
                        <span className="sub-title">Change subtitle</span> 
                    </div>
                    {/* <button className='add-sql-exec-projects' onClick={handleAddProject}>
                        + Add Project
                    </button> */}
                </div>
                <div className="project-details-layout">
                    <SearchFilterSort
                        data={data}
                        onFilter={handleFilter}
                        filterOptions={{ 'All': 'All', 'Active': 'Active', 'Inactive': 'In-Active' }}
                    />
                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header-project-status-tab'>Object</div>
                            <div className='project-column-header-project-status-tab'>Number of Records</div>
                            <div className='project-column-header-project-status-tab'>Status</div>
                            <div className='project-column-header-project-status-tab'>Message</div>
                        </div>
                        <div className="scrollable-box-sql-project-details">
                            {filteredProjects.length > 0 ? (
                            filteredProjects.map(project => (
                            <div key={project.projectid} className='project-status-tab-row'>
                                <div className='project-status-tab-column'>{project.selectedobject}</div>
                                <div className='project-status-tab-column'>{project.reccount}</div>
                                <div className='project-status-tab-column'>{project.status}</div>
                                <div className='project-status-tab-column'>{project.message}</div>
                            </div>
                            ))
                            ) : (
                            <div className='no-projects'>No projects available</div>
                            )}
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
            
    );
}

export default RunHistoryDMEntityLevel;