import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane, FaDatabase, FaSpinner, FaPlug, FaPowerOff } from 'react-icons/fa';
import { IoChatbubbles } from "react-icons/io5";
import { motion, AnimatePresence } from 'framer-motion';
import MessageList from './MessageList';
import DatabasePanel from './DatabasePanel';
import { sendChatMessage } from '../../services/api';
import toast from 'react-hot-toast';
import '../../style/AIChatBot/ChatInterface.css';

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [showDBPanel, setShowDBPanel] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  // const welcomeMessages = [
  //   "Hello, data explorer! 🌟 Ready to dive into some data magic? ✨",
  //   "Hey there! 🚀 Let’s uncover some data secrets together. What can I help you with today? 💡",
  //   "Hi! 👋 I’m your friendly data chatbot 🤖. Ask me anything—I promise not to crash (fingers crossed 🤞)!",
  //   "Welcome! 🧐 Let’s dig into some data mysteries together. 🔍",
  //   "Greetings, human! 👽 I’m here to make your data worries vanish. 💨 What’s on your mind?"
  // ];

  const welcomeMessages = [
    "Welcome to the data party! I'm your AI sidekick for turning numbers into 'aha!' moments.",
    "Hello there! I'm your friendly neighborhood data whisperer. What mysteries shall we solve today?",
    "Hi! I'm like a Swiss Army knife for data analysis, just with fewer sharp edges. What can I help with?",
    "Welcome aboard the SS Data Analysis! I'll be your navigator through the sea of statistics.",
    "Greetings! I'm your AI data companion - think of me as Google meets Excel, but with better jokes.",
    "Hello! I'm here to help turn your data headaches into data high-fives. What's on your mind?",
    "Welcome! I speak fluent CSV, Excel, and human. Let's get your data talking!",
    "Hi there! Ready to be the Sherlock to my Watson? We've got some data mysteries to solve!",
    "Greetings, data explorer! I'm your AI assistant - like a GPS for your data journey, but I actually know where I'm going.",
    "Welcome! Think of me as your data's personal translator - I turn spreadsheet-speak into human-speak.",
    "Hello! I'm your AI data buddy - faster than a spreadsheet, more powerful than a pivot table!",
    "Hi! Ready to do some data magic? I promise not to pull any rabbits out of spreadsheets.",
    "Welcome to your data command center! I'm your AI co-pilot, ready to navigate through your numbers.",
    "Greetings! I'm like a search engine for your data, just don't ask me about the weather.",
    "Hello there! Let's turn those numbers into insights - no calculus degree required!"
  ];

  // const loadingMessages = [
  //   "Hold tight! 🚀 Your data-driven adventure is about to begin! 🗺️",
  //   "Loading your insights… 🚦 Data takes time to reveal its secrets! 🔓"
  // ];

  const loadingMessages = [
    "Crunching numbers at lightning speed (well, almost)...",
    "Teaching the algorithms to dance with your data...",
    "Performing statistical gymnastics...",
    "Convincing the data to reveal its secrets...",
    "Connecting the dots, literally and figuratively...",
    "Translating data-speak into human-speak...",
    "Running calculations faster than a caffeinated mathematician...",
    "Extracting insights with surgical precision...",
    "Polishing results until they shine...",
    "Hunting for patterns in your data wilderness...",
    "Converting coffee into insights...",
    "Asking your data some very personal questions...",
    "Doing data science things...",
    "Making sense of seemingly random numbers...",
    "Ensuring our calculations are actually calculating...",
    "Processing data faster than a squirrel with espresso...",
    "Summoning the power of statistical significance...",
    "Training our neurons to understand your numbers...",
    "Turning raw data into well-cooked insights...",
    "Making spreadsheets tell their story..."
  ];

  const errorMessages = [
    "Well, that was unexpected! Let's try a different approach.",
    "Looks like we've hit a small snag. Mind rephrasing your request?",
    "Hmm, my algorithms are being particularly stubborn. Let's try again.",
    "Even AI assistants have their moments. Shall we give it another shot?",
    "Temporary technical hiccup! Let's try that one more time.",
    "I seem to have lost my train of thought. Could you rephrase that?",
    "My digital crystal ball is a bit foggy. Want to try a different question?",
    "Apparently, I need more coffee in my code. Let's try again.",
    "Error 404: Smooth Operation Not Found. But don't worry, let's retry!",
    "I promise I'm smart, just having a moment. Can we try that again?",
    "My circuits need a quick reboot on this one. Mind trying again?",
    "Looks like we found a puzzle piece that doesn't fit. Let's troubleshoot together.",
    "I seem to have tripped over some code. Back on my feet - let's try again!",
    "That didn't go as planned, but I'm not giving up if you aren't.",
    "Consider this a practice round. Ready for the real attempt?",
    "My algorithms are playing hard to get. Let's rephrase and try again.",
    "Seems I've encountered some digital turbulence. Shall we take another pass?",
    "I know what you're thinking - and yes, this is definitely user error (just kidding!).",
    "Currently experiencing technical difficulties. Have you tried turning me off and on again?",
    "This is slightly embarrassing. Mind if we start fresh?"
  ];



  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(loadingMessages[0]);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(errorMessages[0]);
  const controllerRef = useRef();

  useEffect(() => {
    if (isConnected && !showWelcomeMessage) {
      const randomMessage = welcomeMessages[Math.floor(Math.random() * welcomeMessages.length)];
      const welcomeMsg = {
        id: Date.now(),
        content: randomMessage,
        type: 'bot',
        timestamp: new Date()
      };
      setMessages(prevMessages => [...prevMessages, welcomeMsg]);
      setShowWelcomeMessage(true);
    }
  }, [isConnected, showWelcomeMessage]);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setCurrentLoadingMessage(prevMessage => {
          const currentIndex = loadingMessages.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % loadingMessages.length;
          return loadingMessages[nextIndex];
        });
      }, 11000);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    let interval;
    if (!isLoading && messages.some(msg => msg.type === 'bot' && msg.content.includes("I'm sorry"))) {
      interval = setInterval(() => {
        setCurrentErrorMessage(prevMessage => {
          const currentIndex = errorMessages.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % errorMessages.length;
          return errorMessages[nextIndex];
        });
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isLoading, messages]);

  const handleStopQuery = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      setIsLoading(false);
      toast.success('Query stopped.');
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!input.trim() || !isConnected || isLoading) return;

    const userMessage = {
      id: Date.now(),
      content: input,
      type: 'user',
      timestamp: new Date()
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await sendChatMessage(input);
      const { table_data, natural_language_response, visualization_type } = response;

      const botResponse = {
        id: Date.now() + 1,
        content: natural_language_response,
        type: 'bot',
        timestamp: new Date(),
        tableData: table_data || null,
        visualizationType: visualization_type || null
      };

      setMessages(prevMessages => [...prevMessages, botResponse]);
    } catch (error) {
      toast.error(error.message || 'An error occurred while processing your request');
      const errorMessage = {
        id: Date.now() + 1,
        content: "I'm sorry, but I encountered an error while processing your query. Please try again.",
        type: 'bot',
        timestamp: new Date()
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = () => {
    setIsConnected(false);
    setMessages([]);
    setShowWelcomeMessage(false);
    toast.success('You Are Now Disconnected.');
  }

  return (
    <div className="flex-grow">
      <div className="main-workspace-layout">
          <motion.div 
            className="chat-main"
            animate={{
              width: showDBPanel ? '75%' : '100%',
              transition: {
                type: 'spring',
                damping: 25,
                stiffness: 200
              }
            }}
          >
          <div className="chat-header">
            <div className="header-left">
              <motion.div animate={{ rotate: isConnected ? 360 : 0 }} transition={{ duration: 0.5 }}>
                <IoChatbubbles className={`database-icon ${isConnected ? 'connected' : ''}`} size={42} />
              </motion.div>
              <div>
                <h3 className='page-heading'>InsightlyAI</h3>
                <span className='sub-title'>Turning data into insights, predictions, and smart forecasts</span>
              </div>
            </div>
            <div className="header-right">
              <motion.button className='plug-unplug-button' onClick={isConnected ? handleDisconnect : () => setShowDBPanel(!showDBPanel)}>
                {isConnected ? <FaPowerOff /> : <FaPlug />}
                {isConnected ? 'Unplug' : 'Plug-In'}
              </motion.button>
            </div>
          </div>
          <div className="chat-content">
            <MessageList messages={messages} />
            {isLoading && <div className="loading-message">{currentLoadingMessage}</div>}
          </div>
          <form onSubmit={handleSend} className="chat-input-form">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={isConnected ? 'Ask InsightlyAI anything—your insights are just a question away!' : 'Connect to a database to begin your journey with InsightlyAI.'}
              disabled={!isConnected || isLoading}
            />
            <motion.button type="submit" disabled={!isConnected || isLoading}>
              {isLoading ? <FaSpinner className="spinner" /> : <FaPaperPlane />}
            </motion.button>
          </form>
        </motion.div>
        <AnimatePresence>{showDBPanel && <DatabasePanel onConnect={() => setIsConnected(true)} onClose={() => setShowDBPanel(false)} />}</AnimatePresence>       
      </div>
    </div>
    
  )
}

export default ChatInterface;
