import React, { useState } from 'react';
import '../../style/Signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Signup = ({ setIsSignup }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [temporaryPassword, setTemporaryPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [organizationName, setOrganizationName] = useState('4Seer');
    const [region, setRegion] = useState('');
    // const [attributes, setAttributes] = useState({});
    const [userPoolId, setUserPoolId] = useState('');
    const [awsClientId, setAwsClientId] = useState('');
    const [awsClientSecret, setAwsClientSecret] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const organisationOptions = [
        { value: '4Seer', label: '4Seer' },
        { value: 'Company1', label: 'Company1' },
        { value: 'Company1', label: 'Company1' },
    ];

    const handleClick = () => {
        setIsSignup(false);
    }

const validateForm = () => {
    let formErrors = {};
    if (!firstName) formErrors.firstName = 'First Name is required';
    if (!lastName) formErrors.lastName = 'Last Name is required';
    if (!username) formErrors.username = 'Username is required';
    if (!email) formErrors.email = 'Email is required';
    if (!organizationName) formErrors.organizationName = 'Organisation name is required';
    if (!temporaryPassword) formErrors.password = 'Password is required';
    if (!region) formErrors.password = 'Region is required';
    // if (!attributes) formErrors.password = 'Attributes are required';
    if (!userPoolId) formErrors.password = 'User Pool ID is required';
    if (!awsClientId) formErrors.password = 'AWS Client ID is required';
    if (!awsClientSecret) formErrors.password = 'AWS Client Secret is required';
    
    if (temporaryPassword !== confirmPassword) formErrors.confirmPassword = 'Passwords do not match';
    return formErrors;
};

const getPasswordStrength = (password) => {
    if (password.length === 0) return ''; 
    if (password.length < 6) return 'Weak';
    if (password.match(/[A-Z]/) && password.match(/[0-9]/)) return 'Strong';
    return 'Medium';
};

const handleSignup = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
        try {
            const response = await fetch((`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/create-admin-user`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    temporary_password: temporaryPassword,
                    organization: organizationName,
                    region: region,
                    user_pool_id: userPoolId,
                    aws_client_id: awsClientId,
                    aws_client_secret: awsClientSecret
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setErrors({});
                setFirstName('');
                setLastName('');
                setUsername('');
                setEmail('');
                setTemporaryPassword('');
                setConfirmPassword('');
                setOrganizationName('');
                setRegion('');
                setUserPoolId('');
                setAwsClientId('');
                setAwsClientSecret('');
                localStorage.setItem("user", JSON.stringify(data.data));
                navigate('/');            
            } else {
                const errorData = await response.json();
                if (errorData.error === "Username already exists. Please choose a different username.") {
                    setErrors({ ...errors, username: "Username already exists." });
                } else {
                    setErrors({ submit: errorData.error });
                }
            }
        } catch (error) {
            setErrors({ submit: 'An error occurred during signup' });
        }
    } else {
        setErrors(formErrors);
    }
};


    return (
                <div className='signup-card-right'>
                    <h2>Signup</h2>
                    <form onSubmit={handleSignup} className="signup-form">
                        <div className="form-group-signup">
                            <label>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />
                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                            />
                            {errors.username && <span className="error">{errors.username}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Organisation</label>
                            <select
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            >
                                {organisationOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {errors.organizationName && <span className="error">{errors.organizationName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Temporary Password</label>
                            <div className='input-wrapper-password'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={temporaryPassword}
                                    onChange={(e) => setTemporaryPassword(e.target.value)}
                                    placeholder="Password"
                                />
                                <span className="password-toggle-icon-login-signup" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                            {errors.password && <span className="error">{errors.password}</span>}
                            <div className={`password-strength ${getPasswordStrength(temporaryPassword)}`}>
                                {getPasswordStrength(temporaryPassword)}
                            </div>
                        </div>
                        <div className="form-group-signup">
                            <label>Confirm Password</label>
                            <div className='input-wrapper-password'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirm Password"
                                />
                                <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                            {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                        </div>
                        <div className='form-group-signup'>
                            <label>Region</label>
                            <input
                                type='text'
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                                placeholder='Region'
                            />
                            {errors.region && <span className='error'>{errors.region}</span>}
                        </div>
                        <div className='form-group-signup'>
                            <label>User Pool ID</label>
                            <input
                                type='text'
                                value={userPoolId}
                                onChange={(e) => setUserPoolId(e.target.value)}
                                placeholder='User Pool ID'
                            />
                            {errors.userPoolId && <span className='error'>{errors.userPoolId}</span>}
                        </div>
                        <div className='form-group-signup'>
                            <label>AWS Client ID</label>
                            <input
                                type='text'
                                value={awsClientId}
                                onChange={(e) => setAwsClientId(e.target.value)}
                                placeholder='AWS Client ID'
                            />
                            {errors.awsClientId && <span className='error'>{errors.awsClientId}</span>}
                        </div>
                        <div className='form-group-signup'>
                            <label>AWS Client Secret</label>
                            <input
                                type='text'
                                value={awsClientSecret}
                                onChange={(e) => setAwsClientSecret(e.target.value)}
                                placeholder='AWS Client Secret'
                            />
                            {errors.awsClientSecret && <span className='error'>{errors.awsClientSecret}</span>}
                        </div>                        
                        
                        <div className="form-group-signup form-group-full">
                            <button type="submit">Signup as administrator</button>
                        </div>
                    </form>
                    <div className="signup-footer">
                        <p>Have an account? <Link onClick={handleClick}>Log in here</Link></p>
                    </div>
                </div>
    );
};

export default Signup;
