import React, { useState } from 'react';
import '../../style/UserDetails.css'; 

const UserDetails = ({ user }) => {
    const [profileImage, setProfileImage] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(URL.createObjectURL(file));
        }
    };

    if (!user) {
        return <div className="user-details__no-data">No user details available.</div>;
    }

    return (
        <div className="user-details__container">
            <h2 className="user-details__heading">User Profile</h2>
            <div className="user-details__content">
                {/* Profile Picture Section */}
                <div className="user-details__profile-picture">
                    <div className="user-details__profile-picture-container">
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="user-details__profile-picture-image"
                            />
                        ) : (
                            <span className="user-details__profile-picture-placeholder">No Image</span>
                        )}
                    </div>
                    <label htmlFor="upload-button" className="user-details__upload-label">
                        {profileImage ? 'Change Image' : 'Add Image'}
                    </label>
                    <input
                        id="upload-button"
                        type="file"
                        accept="image/*"
                        className="user-details__upload-input"
                        onChange={handleImageUpload}
                    />
                </div>

                {/* User Details Section */}
                <div className="user-details__info">
                    <div className="user-details__card">
                        <h3>{user.user_name}</h3>
                        <p>
                            <strong>Email:</strong> {user.email}
                        </p>
                        <p>
                            <strong>Department:</strong> {user.department}
                        </p>
                        <p>
                            <strong>Email Verified:</strong> {user.is_email_verified ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Phone Verified:</strong> {user.is_phone_verified ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Active:</strong> {user.is_active ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Creation Date:</strong>{' '}
                            {new Date(user.creation_date).toLocaleDateString()}{' '}
                            {new Date(user.creation_date).toLocaleTimeString()}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
