import React, { useState, useEffect } from 'react';
import '../../style/AdminDashboard.css';
import Home from '../Home';
import AddUser from './AddUser';
import UserDetails from './UserDetails';

const AdminDashboard = () => {
    const [isAddUser, setIsAddUser] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        organization: '',
        first_name: '',
        last_name: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);

    const getGreeting = () => {
        const hours = new Date().getHours();
        if (hours < 12) return 'Good Morning';
        if (hours < 18) return 'Good Afternoon';
        return 'Good Evening';
    };

    // const handleAddUserClick = () => {
    //     setIsAddUser(true);
    // };

    const handleUserClick = (id) => {
        setSelectedUserId(id);
        setIsAddUser(false);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user || !user.client_id) {
                console.warn('Client ID is missing in local storage.');
                return;
            }

            const clientId = user.client_id;
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/admin-users?client_id=${clientId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        }
                    }
                );
                const data = await response.json();
                if (response.ok) {
                    const { active_users, inactive_users } = data.data;
                    const combinedUsers = [...active_users, ...inactive_users];
                    setUsers(combinedUsers);
                } else {
                    console.error('Failed to fetch users:', data.detail);
                }
            } catch (err) {
                console.error('Error fetching users:', err);
            }
        };
    
        fetchUsers();
    }, []);

    // useEffect(() => {
    //     const sampleUsers = [{
    //         "company": null,
    //         "client_id": 66,
    //         "user_name": "loki2121",
    //         "workspace_id": null,
    //         "id": 1025,
    //         "phone_number": null,
    //         "is_email_verified": true,
    //         "department": "test",
    //         "is_phone_verified": false,
    //         "creation_date": "2024-12-26T13:47:35.650000",
    //         "is_active": true,
    //         "profile_link": null,
    //         "email": "liran68578@chosenx.com",
    //         "hashed_password": "$2b$12$aAM8kKFLDNc1nvcSWxggwefdBziYeZaY6H7Zu5SUql7LAcRf8o8Sq"
    //       },
    //       {
    //         "company": null,
    //         "client_id": 66,
    //         "user_name": "user112de",
    //         "workspace_id": null,
    //         "id": 1026,
    //         "phone_number": null,
    //         "is_email_verified": true,
    //         "department": "4seer",
    //         "is_phone_verified": false,
    //         "creation_date": "2024-12-26T13:49:47.977000",
    //         "is_active": true,
    //         "profile_link": null,
    //         "email": "moqiza@thetechnext.net",
    //         "hashed_password": "$2b$12$bnemlaXWEL8f2Bvsj4lJGO0dg1KC/VQzxFk6Of/UvBIH7Gb2xhL4."
    //       },
    //       {
    //         "company": null,
    //         "client_id": 66,
    //         "user_name": "user112dee",
    //         "workspace_id": null,
    //         "id": 1027,
    //         "phone_number": null,
    //         "is_email_verified": true,
    //         "department": "4seer",
    //         "is_phone_verified": false,
    //         "creation_date": "2024-12-26T13:50:44.810000",
    //         "is_active": true,
    //         "profile_link": null,
    //         "email": "derilo@cyclelove.cc",
    //         "hashed_password": "$2b$12$eBgrD4QvUt2DSgZjWW78Du9TKnYGYvIFClUjjpVx.nCqVJ7RD6x7G"
    //       },
    //       {
    //         "company": null,
    //         "client_id": 66,
    //         "user_name": "user112de12",
    //         "workspace_id": null,
    //         "id": 1028,
    //         "phone_number": null,
    //         "is_email_verified": true,
    //         "department": "4seer",
    //         "is_phone_verified": false,
    //         "creation_date": "2024-12-26T13:52:00.233000",
    //         "is_active": false,
    //         "profile_link": null,
    //         "email": "jezekixe@teleg.eu",
    //         "hashed_password": "$2b$12$x0YXvlDhPe4v9sJUNxKth.bhNBuEziJXFE8l6FHs0LvyK7cejJcfa"
    //       }
    //     ]
    //     setUsers(sampleUsers);
    // }, []);
    
    const toggleUserStatus = async (username, isActive) => {
        const action = isActive ? 'disable' : 'enable'; // Chooses the appropriate API
        const apiUrl = `${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/${action}-user?username=${username}`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (response.ok) {
                // Updates the user's status locally
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.user_name === username ? { ...user, is_active: !isActive } : user
                    )
                );
            } else {
                const data = await response.json();
                console.error(`Failed to ${action} user:`, data.detail || 'Unknown error');
            }
        } catch (error) {
            console.error(`Error while trying to ${action} user:`, error);
        }
    };
    
    // const renderUserStatus = (isActive, username) => (
    //     <span
    //         className={`user-status-dot ${isActive ? 'active' : 'inactive'}`}
    //         title={isActive ? 'Active' : 'Inactive'}
    //         onClick={() => toggleUserStatus(username, isActive)}
    //     ></span>
    // );

    const renderUserStatus = (isActive, username) => (
        <label className="toggle-switch">
            <input
                type="checkbox"
                checked={isActive}
                onChange={() => toggleUserStatus(username, isActive)}
            />
            <span className="slider"></span>
        </label>
    );
    
    
    

    // const renderUserStatus = (isActive) => (
    //     <span
    //         className={`user-status-dot ${isActive ? 'active' : 'inactive'}`}
    //         title={isActive ? 'Active' : 'Inactive'}
    //     ></span>
    // );

    return (
        <div className='flex-grow'>
            <div className='main-workspace-layout'>
                <div className='admin-dashboard-column1'>
                    <div className='greeting-plus-row'>
                        <div className='greeting-container'>
                            <h5 className="greeting">{getGreeting()}, User!</h5>
                        </div>
                        <button 
                            className='admin-dashboard-action' 
                            // onClick={handleAddUserClick}>
                            onClick={() => {
                                setIsAddUser(true);
                                setSelectedUserId(null);
                            }}>                        
                            Add User
                        </button>
                        {/* <button className='admin-dashboard-action'>Modify User</button> */}
                    </div>
                    <div className='data-visualisations-section scrollable-box-sql-project-details'>
                        {isAddUser ? (
                            <AddUser 
                                formData={formData}
                                setFormData={setFormData}
                                message={message}
                                setMessage={setMessage}
                                error={error}
                                setError={setError}
                                setIsAddUser={setIsAddUser}
                             />
                        ) : selectedUserId ? (
                            <UserDetails
                                user={users.find((user) => user.id === selectedUserId)}
                            />
                        ) : (
                            <Home />
                        )}
                    </div>
                </div>
                <div className='admin-dashboard-column2'>
                    <div className='user-statistics'>
                        <div className='admin-dashboard-section-headers'>
                            <div>User Statistics</div>
                        </div>                         
                    </div>
                    <div className='active-users-list'>
                        <div className='admin-dashboard-section-headers'>
                            <div>All Users</div>
                        </div>
                        {users.length === 0 ? (
                            <div className='no-users-message'>No user details to display.</div>
                        ) : (
                            <ul className='user-list scrollable-box-all-users-admin-dashboard'>
                                {users.map((user) => (
                                    <li
                                        key={user.id}
                                        className='user-item'
                                        onClick={() => handleUserClick(user.id)}
                                    >
                                        {renderUserStatus(user.is_active, user.user_name)}
                                        <span className='user-name'>{user.user_name}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
