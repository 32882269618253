import React from 'react';
import '../../style/AddUser.css';

const AddUser = ({ formData, setFormData, message, setMessage, error, setError, setIsAddUser }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const refreshToken = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/sign-up`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                setMessage('User added successfully!');
                setIsAddUser(false);
                setFormData({
                    username: '',
                    password: '',
                    email: '',
                    organization: '',
                    first_name: '',
                    last_name: '',
                });
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to add user.');
            }
        } catch (err) {
            setError('An error occurred while adding the user. Please try again.');
        }
    };

    const handleCancel = () => {
        setIsAddUser(false);
        setFormData({
            username: '',
            password: '',
            email: '',
            organization: '',
            first_name: '',
            last_name: '',
        });
        setMessage('');
        setError('');
    };

    return (
        <div className="add-user-container">
            <div className="add-user-left">
                <h2>Welcome, Administrator!</h2>
                <p>Adding a new user to the system is a breeze. Make sure to fill in all the necessary details accurately.</p>
            </div>
            <div className="add-user-right">
                <h3 className="form-header">Add New User</h3>
                <form className="form-container" onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-column">
                            <label>
                                First Name:
                                <input
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Username:
                                <input
                                    type="text"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            
                            <label>
                                Email:
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                        </div>
                        <div className="form-column">
                            <label>
                                Last Name:
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Organization:
                                <input
                                    type="text"
                                    name="organization"
                                    value={formData.organization}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <div className="form-buttons">
                        <button type="submit" className="submit-button">Save</button>
                        <button type="button" className="cancel-button" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </form>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default AddUser;
