import { useState } from "react";
import "../../style/DirectProjectMapping.css";

const DirectProjectMapping = ({ selectedObjects, setSelectedObjects, data }) => {
  // Handle selecting/unselecting a source
  const handleCheckboxChange = (source) => {
    setSelectedObjects((prev) => ({
      ...prev,
      [source]: !prev[source],
    }));
  };

  // Move selected sources directly into the `selectedObjects` state
  const handleMoveToRight = () => {
    setSelectedObjects((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((key) => {
        if (updated[key]) updated[key] = "target";
      });
      return updated;
    });
  };

  // Select all DB objects
  const handleSelectAllDbObjects = () => {
    const allSources = {};
    data.forEach((table) => {
      const source = Object.keys(table)[0];
      allSources[source] = true;
    });
    setSelectedObjects(allSources);
  };

  // Deselect all selected sources
  const handleDeselectAll = () => {
    setSelectedObjects((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((key) => {
        if (updated[key] === true) updated[key] = false;
      });
      return updated;
    });
  };

  // Clear all selections (reset to source)
  const handleUncheckAndMoveToRight = () => {
    setSelectedObjects({});
  };

  return (
    <div className="mapping-container-add-project">
      {/* Sources and Targets Section */}
      <div className="sources-and-targets">
        {/* Sources List */}
        <div className="scrollable-box-direct-mapping sources-list">
          <h5>Source</h5>
          <ul>
            {data.map((table, index) => {
              const source = Object.keys(table)[0];
              return (
                <li key={index}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={selectedObjects[source] === true || false}
                      onChange={() => handleCheckboxChange(source)}
                    />
                    <span>{source}</span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Targets List */}
        <div className="scrollable-box-direct-mapping targets-list">
          <h5>Target</h5>
          <ul>
            {Object.keys(selectedObjects)
              .filter((key) => selectedObjects[key] === "target")
              .map((target, index) => (
                <li key={index}>
                  <span>{target}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {/* Mapping Actions */}
      <div className="mapping-actions">
        <button className="btn-select-all" onClick={handleSelectAllDbObjects}>
          Select All DB Objects
        </button>
        <button className="btn-move-right" onClick={handleMoveToRight}>
          Move To Right
        </button>
        <button className="btn-deselect" onClick={handleDeselectAll}>
          Deselect All
        </button>
        <button className="btn-uncheck" onClick={handleUncheckAndMoveToRight}>
          Clear Selection
        </button>
      </div>
    </div>
  );
};

export default DirectProjectMapping;
