import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaDatabase } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { connectToDatabase } from '../../services/api';
import toast from 'react-hot-toast';
import './../../style/AIChatBot/DatabasePanel.css'

export default function DatabasePanel({ onConnect, onClose }) {
  const [formData, setFormData] = useState({
    server: '',
    database: '',
    username: '',
    password: '',
  });
  const [isConnecting, setIsConnecting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsConnecting(true);

    try {
      await connectToDatabase(formData);
      toast.success('Successfully connected to database!');
      onConnect();
      onClose();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsConnecting(false);
    }
  };

  const formFields = [
    { label: 'Server', key: 'server', placeholder: 'server.database.windows.net' },
    { label: 'Database', key: 'database', placeholder: 'mydatabase' },
    { label: 'Username', key: 'username', placeholder: 'admin' },
    { label: 'Password', key: 'password', type: 'password' },
  ];

  return (
    <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ 
            x: 0,
            opacity: 1,
            transition: {
            type: 'spring',
            damping: 25,
            stiffness: 200,
            when: "beforeChildren"
            }
        }}
        exit={{ 
            x: '100%', 
            opacity: 0,
            transition: {
            type: 'spring',
            damping: 30,
            stiffness: 200,
            when: "afterChildren"
            }
        }}
        transition={{ type: 'spring', damping: 25, stiffness: 200 }}
        className="database-panel"
        >
      <div className="panel-header">
        <FaDatabase />
        <h2>Connect to Database</h2>
        {/* <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className="close-button"
        >
          <IoClose size={20} />
        </motion.button> */}
      </div>

      <form onSubmit={handleSubmit} className="panel-form">
        <div className="form-fields">
          {formFields.map((field) => (
            <motion.div
              key={field.key}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 * formFields.indexOf(field) }}
              className="form-field"
            >
              <label>{field.label}</label>
              <input
                type={field.type || 'text'}
                value={formData[field.key]}
                onChange={(e) =>
                  setFormData({ ...formData, [field.key]: e.target.value })
                }
                placeholder={field.placeholder}
                required
                className="input-field"
              />
            </motion.div>
          ))}
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
        //   disabled={isConnecting}
          className="submit-button"
        >
          {isConnecting ? (
            <>
              <AiOutlineLoading3Quarters className="animate-spin" size={20} />
              Connecting...
            </>
          ) : (
            'Connect'
          )}
        </motion.button>
      </form>
    </motion.div>
  );
}
