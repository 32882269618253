import { useState } from "react";

const ConfigureSourceSystem = ({ formData, setFormData, handlePrevious, connectors, setConnectors, handleNext,data, setData }) => {
    
    const [selectedSource, setSelectedSource] = useState(''); 
    const [sourceConfigFormData, setSourceConfigFormData] = useState({});  
    // const [readDBObjectPayload, setReadDBOnjectPayload] = useState({});
    const [validationStatus, setValidationStatus] = useState(false); 
    const [validationMessage, setValidationMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const configRequirements = {
        "MS SQL Server": {
            "Server": "servernames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MongoDB": {
            "Hostname": "servernames",
            "Port": "ports",
            "AuthDatabase": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "PostgreSQL": {
            "Hostname": "servernames",
            "Port": "ports",
            "Database": "databasenames",
            "Schema": "schema",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure PostgreSQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Schema": "schema",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure SQL Server": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure CosmosDB": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Primary Key": "primary_key",
            "Username": "usernames",
            "Password": "passwords"
        },
        "EXCEL": {
            "File Path": "filepaths"
        },
        "Azure Blob Storage Flatfiles": {
            "AccountName": "accountnames",
            "Account Key": "accountkeys",
            "Container Name": "containernames",
        },
        "Azure Data Lake Table Storage": {
            "Account Name": "accountnames",
            "Account Key": "accountkeys",
            "Container Name": "containernames",
        },
        "Azure Blob Storage Excel": {
            "Account Name": "accountnames",
            "Account Key": "accountkeys",
            "Container Name": "containernames",
            "Excel File Name": "excelfilenames",
        },
        "Azure Blob Storage": {
            "AccountName": "accountnames",
            "AccountKey": "accountkeys",
            "ContainerName": "containernames",
            "Excel File Name": "excelfilenames",
        },
        "Text/CSV": {
            "File Path": "filepaths"
        },
        "OData": {
            "OData URL": "servernames",
            "Tenant ID": "tenant_id",
            "Client ID": "client_id",
            "Client Secret": "client_secret",
            "Scope": "scope",
            "API Key": "api_key",
            "Bearer Token": "bearer_token"
        }
    };
   

    const handleSourceChange = (source) => {
        console.log("Selected source:", source);
        setSelectedSource(source);
        setSourceConfigFormData({ sourcesystem: source });
        setValidationStatus(false); // Resets validation
        setValidationMessage("");
        // setFormData({...formData, sourceValidationStatus: validationStatus});
    };

    const handleValidation = async () => {
        setValidationMessage("");
        setValidationStatus(false); 
        setIsLoading(true);
    
        const generatedPayload = {
            db_type: selectedSource,
            server: sourceConfigFormData.servernames || sourceConfigFormData.hostnames || "",
            database: sourceConfigFormData.databasenames || "",
            username: sourceConfigFormData.usernames || "",
            password: sourceConfigFormData.passwords || "",
            port: sourceConfigFormData.ports ? parseInt(sourceConfigFormData.ports, 10) : 0,
            accountname: sourceConfigFormData.accountnames || "",
            accountkey: sourceConfigFormData.accountkeys || "",
            tenant_id: sourceConfigFormData.tenant_id || "",
            client_id: sourceConfigFormData.client_id || "",
            client_secret: sourceConfigFormData.client_secret || "",
            scope: sourceConfigFormData.scope || "",
            api_key: sourceConfigFormData.api_key || "",
            bearer_token: sourceConfigFormData.bearer_token || "",
        };
    
        try {
            const validationResponse = await fetch(
                `${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/validate-connector`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(sourceConfigFormData),
                }
            );
    
            const validationData = await validationResponse.json();
    
            if (validationData.status === "success") {
                setValidationStatus(true);
                setValidationMessage(validationData.message);
                setFormData({ ...formData, ...sourceConfigFormData });
                console.log("Form Data after source Validation:", formData);
                // setFormData({...formData, sourceValidationStatus: validationStatus});
    
                // Fetch DB objects using the generated payload
                await fetchDBObjects(generatedPayload);

                handleNext();
            } else {
                setValidationStatus(false);
                setValidationMessage(validationData.message);
            }
        } catch (error) {
            setValidationStatus(false);
            setValidationMessage("An error occurred during validation.");
        } finally {
            setIsLoading(false); 
        }
    };
    
    const fetchDBObjects = async (payload) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_FASTAPI_URL}/v1/dbobjects/read-dbobjects`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }
            );
    
            const dbobjects = await response.json();
            setData(dbobjects.data);
            console.log("DBObjects after setting data:", data);
        } catch (error) {
            console.error("Error fetching DB objects:", error);
        }
    };
    

    const renderFields = () => {
        const fields = configRequirements[selectedSource];
        if (!fields) return <span className="config-details-missing">Configuration details for {selectedSource} are missing.</span>;

        return Object.entries(fields).map(([label, key]) => (
            <div key={key} className="form-field">
                <label className="label-sql-add">{label}</label>
                <input
                    type={label.toLowerCase() === "password" ? "password" : "text"}
                    value={sourceConfigFormData[key] || ""}
                    className="input-sql-add"
                    onChange={(e) =>
                        setSourceConfigFormData({ ...sourceConfigFormData, [key]: e.target.value })
                    }
                    placeholder={`Enter ${label.toLowerCase()}`}
                />
            </div>
        ));
    };

    return (
        <div className="form-page-card-inner-layout">
            <div className="form-page-card-source-target-selection">
                {!selectedSource ? (
                    <div className="source-tiles scrollable-box-sql-project-details">
                        {connectors.source.map((connector) => (
                            <div
                                key={connector.value}
                                className="source-tile"
                                onClick={() => handleSourceChange(connector.name)}
                            >
                                <div className="source-target-label">{connector.name}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <h3>{selectedSource} Configuration</h3>
                        <div className="form-two-columns">
                            {renderFields()}
                        </div>
                        <div className="navigation-buttons">
                            <button onClick={() => setSelectedSource("")}>Back to Sources</button>
                            {isLoading ? (
                                <button disabled>
                                    Validating...
                                    <span className="loader"></span> 
                                </button>
                            ) : (
                                <button onClick={handleValidation}>Validate</button>
                            )}
                        </div>
                        {validationMessage && <p>{validationMessage}</p>}
                    </>
                )}
            </div>
        </div>
    );
};

export default ConfigureSourceSystem;
