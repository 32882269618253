import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Project.css';
import {BsFillPencilFill } from 'react-icons/bs';
import AddProjectDialog from './AddProjectDialog';
import NavBar from './NavBar';
import HelpPage from './HelpPage';
import NewPage from './NewPage'; 
import { Link, useNavigate } from 'react-router-dom';
import EditProjectDialog from './EditProjectDialog';
import { FiToggleRight } from "react-icons/fi";
import { FaQuestionCircle } from 'react-icons/fa';
import { CiFilter, CiSearch } from "react-icons/ci";
import '../style/ScrollableBox.css'

const Project = ({ darkMode, purpleMode, redMode, blueMode, yellowMode }) => {
  const [data, setData] = useState([]);
  const [projectNameValidationData, setProjectNameValidationData] = useState([]);
  const [editingProject, setEditingProject] = useState(null);
  const [editingProjectValues, setEditingProjectValues] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showTargetDialog, setShowTargetDialog] = useState(false);
  const [deactivatedProjects, setDeactivatedProjects] = useState([]);
  const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const filterOptions = ['Active', 'In-Active'];
  const [selectedFilter, setSelectedFilter] = useState('')
  const [showDraftProjects, setShowDraftProjects] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickOnRow, setClickOnRow] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();  
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

  const [content, setContent] = useState(Array.from({ length: 50 }, (_, i) => `Item ${i + 1}`));

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const fetchProjects = useCallback(async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_FASTAPI_URL}/v1/project/user-projects?email=${loggedInUserEmail}`
        ); 
        const responseData = await response.json();
        
        
        if (responseData.status === "success" && responseData.data && responseData.data.projects) {
            const projects = responseData.data.projects;
            setData(projects);
            
            const projectNames = projects.map((project) => project.projectname);
            setProjectNameValidationData(projectNames);
        } else {
            console.error('Unexpected response structure:', responseData);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}, [loggedInUserEmail]);

  useEffect(() => {
    fetchProjects();
    console.log('line number: 63');
  }, [fetchProjects]);

  const handleDeactivate = (project) => {
    const project_id = project.projectid;
    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/${project_id}/deactivate?email=${loggedInUserEmail}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          console.log('Project deactivated successfully!');
          setData((prevData) => prevData.map((item) => item.projectname === project.projectname ? { ...item, IsActive: 0 } : item));
          setDeactivatedProjects((prevDeactivatedProjects) => [...prevDeactivatedProjects, project]);         
          fetchProjects();
        } else {
          console.error('Error deactivating project:', responseData.message);
        }
      })
      .catch((error) => {
        console.error('Error deactivating project:', error);
      });
  };

  const handleReactivate = (project) => {
    const project_id = project.projectid;
    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/${project_id}/reactivate?email=${loggedInUserEmail}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 'success') {
          console.log('Project reactivated successfully!');
          setData((prevData) => prevData.map((item) => item.projectname === project.projectname ? { ...item, IsActive: 0 } : item));
          setDeactivatedProjects((prevDeactivatedProjects) => [...prevDeactivatedProjects, project]);
          fetchProjects();
        } else {
          console.error('Error reactivating project:', responseData.message);
        }
      })
      .catch((error) => {
        console.error('Error reactivating project:', error);
      });
  };

  const saveDataToBackend = (newProjectData) => {
    fetch(process.env.REACT_APP_API_URL+'save_newproject', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({useremail:newProjectData.useremail, projectname:newProjectData.projectname,
        sourcesystem:newProjectData.sourcesystem, servernames:newProjectData.servernames, databasenames:newProjectData.databasenames,usernames:newProjectData.usernames,passwords:newProjectData.passwords,filepaths:newProjectData.filepaths, hostnames:newProjectData.hostnames, ports:newProjectData.ports, protocols:newProjectData.protocols, dbqs:newProjectData.dbqs, users:newProjectData.users, db_names:newProjectData.db_names
        ,taregtsystem:newProjectData.taregtsystem, servernamet:newProjectData.servernamet, databasenamet:newProjectData.databasenamet,usernamet:newProjectData.usernamet,passwordt:newProjectData.passwordt,filepatht:newProjectData.filepatht, hostnamet:newProjectData.hostnamet, portt:newProjectData.portt, protocolt:newProjectData.protocolt, dbqt:newProjectData.dbqt, usert:newProjectData.usert, db_namet:newProjectData.db_namet }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data.status === 'success') {
        console.log('Data saved successfully to the backend!');
      } else {
        console.error('Error saving data to the backend:', data.message);
      }
    })
      .catch((error) => {
        console.error('Error saving data to the backend:', error);
      });
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditingProjectValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    setIsFilterActive(true);
  };

  const showHelpPage = () => {
    setIsHelpPageOpen(true);
  };

  const closeHelpPage = () => {
    setIsHelpPageOpen(false);
  };
  


  const handleSaveEdit = () => {
    if (
      !editingProjectValues.projectname.trim() ||
      !editingProjectValues.sourcesystem.trim() ||
      !editingProjectValues.targetsystem.trim()
    ) {
      return;
    }

    const currentDate = new Date();
    const lastUpdateDate = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    const updatedData = data?.map((project) =>
      project === editingProject ? { ...editingProjectValues, LastUpdateDate: lastUpdateDate } : project
    );
    
    setData(updatedData);
    setEditingProject(null);
    setEditingProjectValues({});
    saveDataToBackend(updatedData);
  };

  const handleCancelEdit = () => {
    setEditingProject(null);
    setEditingProjectValues({});
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleHelpPage = () => {
    setIsHelpPageOpen(!isHelpPageOpen);
  };

  useEffect(() => {
    // Open the help page automatically when the component mounts
    setIsHelpPageOpen(false);
  }, []);

  const filteredData = data?.filter((project) => {
    const { projectname, sourcesystem, targetsystem, createddate, LastUpdateDate, status, sourceconfiguration, targetconfiguration } = project;
    const searchLowerCase = searchQuery.toLowerCase();

    return (
      (!selectedFilter || selectedFilter === 'All' || project.status === selectedFilter) &&
      (!showDraftProjects || project.status === 'Draft') &&
      ((projectname && projectname.toLowerCase().includes(searchLowerCase)) ||
        (sourcesystem && sourcesystem.toLowerCase().includes(searchLowerCase)) ||
        (targetsystem && targetsystem.toLowerCase().includes(searchLowerCase)) ||
        (createddate && createddate.toLowerCase().includes(searchLowerCase)) ||
        (LastUpdateDate && LastUpdateDate.toLowerCase().includes(searchLowerCase)))
    );
  });

  const handleSaveNewProject = (newProjectData) => {
    if (!newProjectData.projectname.trim() || !newProjectData.sourcesystem.trim() || !newProjectData.taregtsystem.trim()) {
      return;
    }

    const currentDate = new Date();
    const createdDate = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    const newProject = {
      projectname: newProjectData.name,
      sourcesystem: newProjectData.source,
      targetsystem: newProjectData.target,
      createddate: createdDate,
      LastUpdateDate: createdDate,
    };

    const updatedData = [...data, newProject];
    setData(updatedData);
    setIsDialogOpen(false);
    saveDataToBackend(updatedData);
    fetchProjects();
  };

  const openEditDialog = (project) => {
    setEditingProject(project);
    setEditingProjectValues(project);
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditingProject(null);
    setEditingProjectValues({});
    setIsEditDialogOpen(false);
  };

  

  const handleSortBy = (option) => {
    const sortedData = [...data];
    const isAsc = sortOrder === 'asc';
  
    switch (option) {
      case 'date':
        sortedData.sort((a, b) => (isAsc ? new Date(a.createddate) - new Date(b.createddate) : new Date(b.createddate) - new Date(a.createddate)));
        break;
        case 'status':
          sortedData.sort((a, b) => {
            const statusA = a.status === "Active" ? 0 : 1;
            const statusB = b.status === "Active" ? 0 : 1;
    
            if (isAsc) {
              return statusA - statusB;
            } else {
              return statusB - statusA;
            }
          });
          break;
          case 'update':
            sortedData.sort((a, b) => (isAsc ? new Date(a.LastUpdateDate) - new Date(b.LastUpdateDate) : new Date(b.LastUpdateDate) - new Date(a.LastUpdateDate)));
            break;
          default: break;
    }
  
    setData(sortedData);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };
  
  return (
    <div className={`flex ${darkMode ? 'dark-mode' : ''}`}>
      {/* <NavBar />*/}
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        /> 
    <div className="flex-grow">
    <div>
      <nav aria-label="breadcrumb">
        <ul className="d-flex breadcrumb">
          <li className="breadcrumb-item">
          <Link
              to={{pathname: '/',}}
              className={`inactive-link ${darkMode ? 'dark-mode' : ''}`}>
                Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <a href="/Project" className={`active-link ${darkMode ? 'dark-mode' : ''}`}>
              Active Projects
            </a>
          </li>
        </ul>
      </nav>
      <div className="mt-2 d-flex justify-content-between mb-4">
        <div className='Mainname'>
          <h4 className={`page-heading ${darkMode ? 'dark-mode' : ''}`}>ACTIVE PROJECT</h4>
          <span className={`sub-title ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
            Create, Edit and Manage your projects
          </span> 

        </div>
        <button className="add-project-button"  onClick={() => setIsDialogOpen(true)}>
          <span>+ ADD PROJECT</span> 
        </button>
      </div>
      <ul
        className="nav nav-tabs d-flex align-items-center"
        id="myTab"
        role="tablist"
      >
        <li className={`nav-item ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`} role="presentation">
          <a
            className={`nav-link active ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}
            id="overview-tab"
            data-bs-toggle="tab"
            href="#overview"
            role="tab"
            aria-controls="overview"
            aria-selected="true"
          >
            Overview
          </a>
        </li>
        <li className="flex-grow-1 d-flex justify-content-end align-items-center">
          <span className={`sort-container ${darkMode ? 'dark-mode' : ''}`}>Sort By:</span>
          <div className="form-check form-check-inline custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="sortOption"
            id="sortByCreationDate"
            value="creationDate"
            checked={handleSortBy === 'date'}
            onChange={() => handleSortBy('date')}
            />            
            <label className={`form-check-label ${darkMode ? 'dark-mode' : ''}`} htmlFor="sortByCreationDate">
              Creation Date
            </label>
          </div>
          <div className="form-check form-check-inline custom-radio">
          <input         
              className="form-check-input"
              type="radio"
              name="sortOption"
              id="sortByCreationDate"
              value="Status"
              checked={handleSortBy === 'update'}
              onChange={() => handleSortBy('update')}
            />
            <label className="form-check-label" htmlFor="sortByLastUpdate">
              Last Update
            </label>
          </div>
          <div className="form-check form-check-inline custom-radio">
           <input
            className="form-check-input"
            type="radio"
            name="sortOption"
            id="sortByActiveStatus"
            value="status"
            checked={handleSortBy === 'status'} 
            onChange={() => handleSortBy('status')}
            />
       <label className="form-check-label" htmlFor="sortByActiveStatus">
        Active Status
        </label>
          </div>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active" 
          id="overview"
          role="tabpanel"
          aria-labelledby="overview-tab"
        >
          <div className={`d-flex justify-content-between mt-4 search-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
            <div className={`input-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''}`}>
              <input
                type="text"
                placeholder="Search by project name."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="icon">
                <CiSearch />
              </div>
            </div>
            <div>
              <button className="filter-button" onClick={() => setIsFilterActive(!isFilterActive)}>
                <CiFilter className="unfilled-icon" /> Filter
              </button>
              {isFilterActive && (
          <div className="filter-options">
            <button
              className={`filter-option ${selectedFilter === 'All' ? 'active' : ''}`}
              onClick={() => handleFilterChange('All')}
            >
              All
            </button>
            {filterOptions.map((option) => (
              <button
                key={option}
                className={`filter-option ${selectedFilter === option ? 'active' : ''}`}
                onClick={() => handleFilterChange(option)}
              >
                {option}
              </button>
            ))}
          </div>
        )}
       </div>
       </div>
       <div className="custom-table">
            <div className={`custom-table-header ${darkMode ? 'dark-mode' : ''}`}>
              <div style={{ flex: 1 }}>PROJECT</div>
              <div style={{ flex: 1 }}>SOURCE SYSTEM</div>
              <div style={{ flex: 1 }}>TARGET SYSTEM</div>
              <div style={{ flex: 1 }}>LAST UPDATE</div>
              <div style={{ flex: 1, textAlign: 'center' }}>ACTIONS</div>
            </div>
      </div>
      <div className={`scrollable-box ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
          <div className="mt-2">
          <div className="custom-table">
                  <div className="custom-table-body">
                        {filteredData.map((item, index) => (
                          <div
                            key={index}
                            className={`custom-table-row`}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => setClickOnRow(index)}
                          >
                            <div className="custom-table-cell custom-left">
                              <span className="active">
                                <button
                                  className={`status-button ${
                                    item.status === "Active" ? "active-status" : "In-Active"
                                  }`}
                                >
                                  {item.status === "Active" ? "🟢" : "🔴"}
                                </button>
                              </span>
                              <Link
                                to={{
                                  pathname: `/Connector/${item.projectid}`,
                                  state: { projectData: item },
                                }}
                                className="link-styling"
                              >
                                {item.projectname}
                              </Link>
                            </div>
                            <div className={`custom-table-cell ${darkMode ? 'dark-mode' : ''}`} >{item.sourcesystem}</div>
                            <div className={`custom-table-cell ${darkMode ? 'dark-mode' : ''}`} >{item.targetsystem}</div>
                            <div className={`custom-table-cell ${darkMode ? 'dark-mode' : ''}`} >{item.lastupdatedate}</div>
                            <div className="custom-table-cell custom-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <label className="toggle-switch small-toggle" style={{ position: 'relative', display: 'inline-block' }}>
                                <input
                                  type="checkbox"
                                  checked={item && item.status !== "In-Active"}
                                  onChange={() => {
                                    if (item) {
                                      if (item.status === "In-Active") {
                                        handleReactivate(item);
                                      } else {
                                        handleDeactivate(item);
                                      }
                                    }
                                  }}
                                />
                                <span className="slider"></span>
                              </label>
                                <div className="custom-overlay">
                                  <span className="edit-link">
                                    <span className={`edit-text ${darkMode ? 'dark-mode' : ''}`}>Edit</span>
                                    <BsFillPencilFill
                                      className={`pencil-icon ${darkMode ? 'dark-mode' : ''}`}
                                      onClick={() => openEditDialog(item)}
                                    />
                                  </span>
                              </div>
                            </div>
                            {/* <div className="custom-table-cell custom-right" style={{ display: 'flex', justifyContent: 'center' }}>

                            </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

           </div>
       <div
          className="tab-pane fade"
          id="drafts"
          role="tabpanel"
          aria-labelledby="drafts-tab"
        >
          Content for the Drafts tab goes here.
        </div>
      </div>
    </div>
    <div className="help-icon" >
    <div className=
      {` ${isHelpPageOpen ? 
                      `help-container ${darkMode ? 'dark-mode' : ''}`: `help-container-closed ${darkMode ? 'dark-mode' : ''}`}`}>      
      <p className={` ${isHelpPageOpen ? `menu-text-help-bot ${darkMode ? 'dark-mode' : ''}` : `question-mark-style ${darkMode ? 'dark-mode' : ''} `}`} onMouseEnter={showHelpPage} onClick={closeHelpPage} >
        {isHelpPageOpen ? 'May I help you?' : '?'}
       </p>
        {isHelpPageOpen && <HelpPage onClose={toggleHelpPage} darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />}                  
      </div>
    </div>     
      
      {isDialogOpen && (
          <AddProjectDialog
            isOpen={isDialogOpen}
            onClose={() => {
              fetchProjects();
              setIsDialogOpen(false);          
            }}
            onSave={handleSaveNewProject}
            fetchProjects={fetchProjects} 
            showTargetDialog={showTargetDialog}
            setShowTargetDialog={setShowTargetDialog}
            projectNameValidationData = {projectNameValidationData}
          />
        )}

      {isEditDialogOpen && (
        <EditProjectDialog
          isOpen={isEditDialogOpen}
          onClose={closeEditDialog}
          project={editingProject}
          editingProjectValues={editingProjectValues}
          handleInputChange={handleInputChange}
          handleSaveEdit={handleSaveEdit}
          handleCancelEdit={handleCancelEdit}
        />
      )}
     </div>
     </div>
);
}
export default Project;