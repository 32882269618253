const API_BASE_URL = 'http://localhost:8080/api';

const apiRequest = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      ...options,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.detail || 'An error occurred');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || 'An unexpected error occurred');
  }
};

export const connectToDatabase = async (credentials) => {
    console.log('Attempting to connect with credentials:', credentials);
    try {
      const response = await apiRequest('/v1/chatbot/connect', {
        method: 'POST',
        body: JSON.stringify(credentials),
      });
      console.log('API response:', response);
      return response;
    } catch (error) {
      console.error('Error connecting to database:', error);
      throw new Error(error.message || 'Failed to connect to database');
    }
};
  

export const sendChatMessage = async (message) => {
  try {
    return await apiRequest('/v1/chatbot/query', {
      method: 'POST',
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    throw new Error(error.message || 'Failed to process query');
  }
};
