import React, { useState, useEffect } from "react";
import BreadcrumbItem from "./Breadcrumbs";
import '../style/SQLExecAddProject.css';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const SQLExecAddProject = () => {
    const [currentPage, setCurrentPage] = useState(1); 
    const [formData, setFormData] = useState({});     

    const handleNext = () => {
        if (currentPage < 3) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const renderPage = () => {
        switch (currentPage) {
            case 1:
                return <Page1 formData={formData} setFormData={setFormData} handleNext={handleNext}/>;
            case 2:
                return <Page2 formData={formData} setFormData={setFormData} handlePrevious={handlePrevious} handleNext={handleNext} />;
            case 3:
                return <Page3 formData={formData} setFormData={setFormData} handlePrevious={handlePrevious} />;
            default:
                return null;
        }
    };

    // const renderActionButtons = () => {
    //     switch (currentPage) {
    //         case 1:
    //             return (<div><button onClick={handlePrevious}>Previous</button></div>);
    //         case 2:
    //             return <Page2 formData={formData.page2} setFormData={(data) => setFormData({...formData, page2: data})} />;
    //         case 3:
    //             return <Page3 formData={formData.page3} setFormData={(data) => setFormData({...formData, page3: data})} />;
    //         default:
    //             return null;
    //     }
    // };

    const renderProgressIndicator = () => (
            <div className="progress-indicator">
                <div className={`step-sql-add ${currentPage === 1 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 1 ? 'active' : ''}`}>1</span>
                    <span className="step-text">Project Name</span>
                </div>
                <div className={`step-sql-add ${currentPage === 2 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 2 ? 'active' : ''}`}>2</span>
                    <span className="step-text">Configure ODS</span>
                </div>
                <div className={`step-sql-add ${currentPage === 3 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 3 ? 'active' : ''}`}>3</span>
                    <span className="step-text">SQL Executions</span>
                </div>
            </div>
    );

    return (
        <div className='flex-grow'>
            <Toaster />
            <div className="sqlprojects-layout">
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/sqlexecprojects" label="Active SQL Projects" isActive={false} />
                        <BreadcrumbItem to="/add-sql-project" label="Add SQL Projects" isActive={true} />
                    </ul>
                </nav>
                <div className="add-projects-sql-header-layout">
                    {renderProgressIndicator()}
                </div>
                <div className="add-projects-sql-card-layout">
                    {renderPage()}
                    {/* <div className="navigation-buttons">
                        {currentPage > 1 && <button onClick={handlePrevious}>Previous</button>}
                        {currentPage < 3 && <button onClick={handleNext}>Next</button>}
                        {currentPage === 3 && <button onClick={() => console.log('Submit form')}>Submit</button>}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const Page1 = ({ formData, setFormData, handleNext }) => {
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;    
    return (
    <div className="form-page-card-inner-layout">
        <div className="form-page-card">
            <label className='label-sql-add'>Project Name</label>
            <input
                type="text"
                value={formData.projectname || ''}
                className="input-sql-add"
                onChange={(e) => setFormData({ ...formData, useremail: loggedInUserEmail, projectname: e.target.value })}
                placeholder="Enter project name"
            />
            <label className='label-sql-add'>ODS</label>
            <select
                value={formData.ods || ''}
                className="input-sql-add"
                onChange={(e) => setFormData({ ...formData, ods: e.target.value })}
            >
                <option value="" disabled>Select ODS</option>
                <option value="PostgreSQL">PostgreSQL</option>
                <option value="ODS2">Azure SQL Server</option>
                <option value="ODS3">ODS 3</option>
            </select>
        </div>
        <div className="navigation-buttons-page-1">
            <button onClick={handleNext}>Next</button>
        </div>
    </div>
    )
};

const Page2 = ({ formData, setFormData, handlePrevious, handleNext }) => {
    const [validationStatus, setValidationStatus] = useState(false); 
    const [validationMessage, setValidationMessage] = useState(""); 

    const handleValidation = async () => {
        setValidationMessage("");  
        const dataToValidate = {
            ods: "PostgreSQL",  
            hostname: formData.host_name,
            port: formData.port,
            username: formData.user_name,
            database: formData.database_name,
            password: formData.password,
            schema_: formData.schema_
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/validate-odsconnector`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToValidate),
            });

            const data = await response.json();

            if (response.ok) {
                setValidationStatus(true);  
                setValidationMessage(data.message);  
            } else {
                setValidationStatus(false);  
                setValidationMessage(data.message); 
            }
        } catch (error) {
            setValidationStatus(false);
            setValidationMessage("An error occurred during validation.");
        }
    };

    return (
        <div className="form-page-card-inner-layout">
            <div className="form-page-card">
                <div className="form-two-columns">
                    <div className="form-column">
                        <label className='label-sql-add'>Hostname</label>
                        <input
                            type="text"
                            value={formData.host_name || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, host_name: e.target.value })}
                            placeholder="Enter hostname"
                        />
                        <label className='label-sql-add'>Port</label>
                        <input
                            type="text"
                            value={formData.port || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, port: e.target.value })}
                            placeholder="Enter port"
                        />
                        <label className='label-sql-add'>Schema Name</label>
                        <input
                            type="text"
                            value={formData.schema_ || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, schema_: e.target.value })}
                            placeholder="Enter schema"
                        />                
                    </div>
                    <div className="form-column">
                        <label className='label-sql-add'>Database Name</label>
                        <input
                            type="text"
                            value={formData.database_name || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, database_name: e.target.value })}
                            placeholder="Enter database name"
                        />
                        <label className='label-sql-add'>Username</label>
                        <input
                            type="text"
                            value={formData.user_name || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, user_name: e.target.value })}
                            placeholder="Enter username"
                        />
                        <label className='label-sql-add'>Password</label>
                        <input
                            type="password"
                            value={formData.password || ''}
                            className="input-sql-add"
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            placeholder="Enter password"
                        />
                    </div>
                </div>
            </div>
            {validationMessage && <span className="error-sql-exec-add-projects">{validationMessage}</span>}
            <div className="navigation-buttons">
                <button onClick={handlePrevious}>Previous</button>
                {validationStatus ? 
                    <button onClick={handleNext}>Next</button>
                    : <button onClick={handleValidation}>Validate</button>
                }
            </div>
        </div>
    );
};

const Page3 = ({ formData, setFormData, handlePrevious }) => {
    const [storedProcedures, setStoredProcedures] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStoredProcedures = async () => {
            try {
                const params = new URLSearchParams({
                    host_name: formData.host_name,
                    port: formData.port,
                    user_name: formData.user_name,
                    database: formData.database_name,
                    password: formData.password,
                    schema_: formData.schema_,
                });

                const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/sql-projects/postgresql-storedprocs?${params.toString()}`, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch stored procedures');
                }

                const data = await response.json();
                setStoredProcedures(data.data || []); 
            } catch (error) {
                console.error('Error fetching stored procedures:', error);
            }
        };

        fetchStoredProcedures();
    }, [formData]);

    const handleProcedureSelect = (name) => {
        setSelectedProcedures((prev) => {
            const updatedProcedures = prev.includes(name)
                ? prev.filter((procName) => procName !== name)
                : [...prev, name];
            setFormData({ ...formData, sql_storeprocedures_selected: updatedProcedures });
            return updatedProcedures;
        });
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/sql-projects/new-sqlproject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), 
            });

            if (!response.ok) {
                throw new Error('Failed to submit form');
            }
            const data = await response.json();
            console.log('Form submitted successfully:', data);
            toast.success('Added project successfully!');
            setTimeout(() => {
                navigate('/sqlexecprojects');
            }, 1000);           
            
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Error submitting form');
        }
    };

    const handleSaveAndRun = async () => {
        try {
            const saveResponse = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/sql-projects/new-sqlproject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (!saveResponse.ok) {
                throw new Error('Failed to save SQL project');
            }
    
            const saveData = await saveResponse.json();
            console.log('SQL project saved successfully:', saveData);
            toast.success('SQL project saved successfully');
    
            if (saveData.status === 'success' && saveData.project_id) {                    
                const syncResponse = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/data_sync/sql-data-sync?project_id=${saveData.project_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!syncResponse.ok) {
                    throw new Error('Failed to sync SQL project');
                }
    
                const syncData = await syncResponse.json();
                if (syncData.status === 'success') {
                    toast.success('SQL project data sync executed successfully');
                    setTimeout(() => {
                        navigate('/sqlexecprojects');
                    }, 1000);
                    console.log('SQL project data sync executed successfully:', syncData);
                    
                } else {
                    console.error('Error syncing SQL project:', syncData.message);
                    toast.error('Error syncing SQL project:', syncData.message);
                }
            } else {
                console.error('Error saving SQL project:', saveData.message);
                toast.error('Error saving SQL project:', saveData.message);
            }
        } catch (error) {
            console.error('Error handling save and run:', error);
            toast.error('Error handling save and run');
        }
    };


    return (
        <div>
            <div className="form-page-card">
                <div className="stored-procedures-header">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search stored procedures"
                    />
                </div>

                <div className="stored-procedures-list">
                    {storedProcedures.map((proc, index) => (
                        <div key={index} className="stored-procedure-item">
                            <input
                                type="checkbox"
                                id={`proc-${index}`}
                                checked={selectedProcedures.includes(proc)}
                                onChange={() => handleProcedureSelect(proc)}
                            />
                            <label htmlFor={`proc-${index}`}>{proc}</label>
                        </div>
                    ))}
                </div>

                <div className="selected-procedures">
                    {selectedProcedures.map((procName) => (
                        <span key={procName} className="selected-tag">{procName}</span>
                    ))}
                </div>
            </div>
            <div className="navigation-buttons">
                <button onClick={handlePrevious}>Previous</button>
                <div className="navigation-buttons-page-3">
                    <button onClick={handleSave}>Save</button>
                    <button onClick={handleSaveAndRun}>Save and Run</button>
                </div>                
            </div>
            <Toaster />
        </div>
    );
};


export default SQLExecAddProject;