const ProjectDetailsEntry = ({ formData, setFormData, handleNext, projectNameValidationData }) => {
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;  
    const LoadTypeOptions = [
        { value: 'direct', label: 'Direct' },
        { value: 'incremental', label: 'Incremental' },
      ];

    return (
    <div className="form-page-card-inner-layout">
        <div className="form-page-card">
            <label className='label-sql-add'>Project Name</label>
            <input
                type="text"
                value={formData?.projectname || ''}
                className="input-sql-add"
                onChange={(e) => setFormData({ ...formData, useremail: loggedInUserEmail, projectname: e.target.value })}
                placeholder="Enter project name"
            />
            <label className="label-sql-add">Load Type</label>
            <select
                options={LoadTypeOptions}
                value={formData?.load_type || ''}
                onChange={(e) => setFormData({ ...formData, load_type: e.target.value })}
                className="input-sql-add"
            >
                <option value="" disabled>Select Load Type</option>
                <option value="direct">Direct</option>
                <option value="incremental">Incremental</option>
            </select>
        </div>
        {/* <div className="navigation-buttons-page-1">
            <button onClick={handleNext}>Next</button>
        </div> */}
    </div>
    )
};

export default ProjectDetailsEntry;