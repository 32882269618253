import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/Sidebar4.css';

import { RiFolder2Line } from 'react-icons/ri';
import { PiClockCounterClockwiseBold } from 'react-icons/pi';
import { FiCalendar, FiLogOut } from 'react-icons/fi';
import { IoLogOut,IoCalendarClear } from "react-icons/io5";
import { MdOutlineManageHistory, MdEditSquare } from 'react-icons/md';
import { BsFolderFill } from "react-icons/bs";
import { PiFileSqlFill } from "react-icons/pi";
import { FaCog, FaHome } from 'react-icons/fa';
import { FaUserCircle, FaHistory } from "react-icons/fa";
import { SiChatbot } from "react-icons/si";

const Sidebar4 = () => {
  const [selectedIcon, setSelectedIcon] = useState(0);
  const navigate = useNavigate();

  const menuItems = [
    { id: 0, icon: <FaHome size={20} />, link: "/admin-dashboard" },
    { id: 1, icon: <BsFolderFill size={20} />, link: "/all-projects" },
    { id: 2, icon: <PiFileSqlFill size={20} />, link: "/sql-execution-projects" },
    { id: 3, icon: <IoCalendarClear size={20} />, link: "/scheduled-job-details" },
    { id: 4, icon: <FaHistory size={20} />, link: "/run-history-project-status" },
  ];

  const userControlItems = [
    { id: 5, icon: <MdEditSquare size={20} />, link: "/editor" },
    { id: 6, icon: <SiChatbot size={20} />, link: "/ai-chatbot" },
  ];

  const userProfileItems = [
    { id: 7, icon: <IoLogOut size={20} />, link: "/login", action: "logout" },
    { id: 8, icon: <FaUserCircle size={20} />, link: "/profile" },
  ];

  const calculateHighlightTop = () => {
    if (selectedIcon < menuItems.length) {
      return `${selectedIcon * 3.25}rem`;
    } else if (selectedIcon < menuItems.length + userControlItems.length) {
      return `${(selectedIcon - menuItems.length) * 3.25}rem`;
    } else {
      return `${(selectedIcon - menuItems.length - userControlItems.length) * 3.25}rem`;
    }
  };

  const handleLogout = () => {

    // localStorage.removeItem('authToken'); 
    localStorage.clear();

    navigate('/login');
  };

  const handleMenuClick = (item) => {
    setSelectedIcon(item.id);
    if (item.action === "logout") {
      handleLogout();
    }
  };

  return (
    <div className="minimalist-sidebar">
      <div className="main-user-sections">
        {/* Main Menu Section */}
        <div className="main-menu-section">
          {selectedIcon < menuItems.length && (
            <div
              className="circle-highlight"
              style={{ top: calculateHighlightTop() }}
            ></div>
          )}
          {menuItems.map((item) => (
            <Link
              to={item.link}
              key={item.id}
              className={`menu-item-minimalist-sidebar ${
                selectedIcon === item.id ? 'active' : ''
              }`}
              onClick={() => handleMenuClick(item)}
            >
              {item.icon}
            </Link>
          ))}
        </div>

        {/* User Control Section */}
        <div className="user-control-section">
          {selectedIcon >= menuItems.length &&
            selectedIcon < menuItems.length + userControlItems.length && (
              <div
                className="circle-highlight"
                style={{ top: calculateHighlightTop() }}
              ></div>
            )}
          {userControlItems.map((item) => (
            <Link
              to={item.link}
              key={item.id}
              className={`menu-item-minimalist-sidebar ${
                selectedIcon === item.id ? 'active' : ''
              }`}
              onClick={() => handleMenuClick(item)}
            >
              {item.icon}
            </Link>
          ))}
        </div>
      </div>

      {/* User Profile Section */}
      <div className="user-profile-section">
        {selectedIcon >= menuItems.length + userControlItems.length && (
          <div
            className="circle-highlight"
            style={{ top: calculateHighlightTop() }}
          ></div>
        )}
        {userProfileItems.map((item) => (
          <Link
            to={item.link}
            key={item.id}
            className={`menu-item-minimalist-sidebar ${
              selectedIcon === item.id ? 'active' : ''
            }`}
            onClick={() => handleMenuClick(item)}
          >
            {item.icon}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar4;
