import React, { useState, useEffect } from "react";
import BreadcrumbItem from "../Breadcrumbs";
import '../../style/SQLExecAddProject.css';
import '../../style/AddProject.css';
import { useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import ProjectDetailsEntry from "./ProjectDetailsEntry";
import ConfigureSourceSystem from "./ConfigureSourceSystem";
import ConfigureTargetSystem from "./ConfigureTargetSystem";
import DirectProjectMapping from "./DirectProjectMapping";
import IncrementalProjectMapping from "./IncrementalProjectMapping"; 
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

const steps = [
  { id: 1, title: "Project Details", component: ProjectDetailsEntry },
  { id: 2, title: "Configure Source System", component: ConfigureSourceSystem },
  { id: 3, title: "Configure Target System", component: ConfigureTargetSystem },
  { id: 4, title: "Mapping" }, // Leaving component empty here to dynamically render it later
];

const AddProject = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({ projectname: '', load_type: 'direct' }); // Default load_type is "direct"
  const location = useLocation();
  const projectNameValidationData = location.state?.projectNameValidationData || [];
  const [connectors, setConnectors] = useState([]);
  const [data, setData] = useState([]); 
  const [selectedObjects, setSelectedObjects] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSaving2, setIsSaving2] = useState(false); //Saving check for Save And Run
  const [isRunning, setIsRunning] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColumnsSource, setSelectedColumnsSource] = useState({});
  const [tableHasPrimaryKey, setTableHasPrimaryKey] = useState([]);
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const navigate = useNavigate();

  // Validation for each step
  const validateStep = (step) => {
    if (step === 1) {
      const projectname = formData.projectname || '';
      if (projectname.trim() === '') {
        toast.error('Please enter the project name.');
        return false;
      }
      if (projectNameValidationData.includes(projectname)) {
        toast.error('Project name already exists. Please choose a different name.');
        return false;
      }
    }

    // if (step === 2) {
    //   if (!formData?.sourceValidationStatus) {
    //     toast.error('Please select a source system configuration.');
    //     return false;
    //   }
    // }

    

    return true;
  };

  // Navigation handlers
  const handleNext = () => {
    if (currentPage < steps.length && validateStep(currentPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Render progress indicator
  const renderProgressIndicator = () => (
    <div className="progress-indicator">
      {steps.map((step) => (
        <div key={step.id} className={`step-sql-add ${currentPage === step.id ? 'active' : ''}`}>
          <span className={`circle-icon ${currentPage === step.id ? 'active' : ''}`}>
            {step.id}
          </span>
          <span className="step-text">{step.title}</span>
        </div>
      ))}
    </div>
  );

  const getCurrentStepComponent = () => {
    const step = steps.find((step) => step.id === currentPage);
  
    if (!step) return null;
  
    // Handle dynamic rendering for "Mapping" step
    if (step.title === "Mapping") {
      const MappingComponent =
        formData.load_type === "incremental"
          ? IncrementalProjectMapping
          : DirectProjectMapping;
  
      return (props) => (
        <MappingComponent
          {...props}
          connectors={connectors}
          setConnectors={setConnectors}
          data={data}
          setData={setData}
          selectedObjects={selectedObjects}
          setSelectedObjects={setSelectedObjects}
          activeTable = {activeTable}
          setActiveTable = {setActiveTable}
          selectedColumns = {selectedColumns}
          setSelectedColumns = {setSelectedColumns}
          selectedColumnsSource ={selectedColumnsSource}
          setSelectedColumnsSource = {setSelectedColumnsSource}
          tableHasPrimaryKey = {tableHasPrimaryKey}
          setTableHasPrimaryKey = {setTableHasPrimaryKey}
        />
      );
    }
  
    const Component = step.component;
  
    // Pass `connectors` and `setConnectors` only to relevant components
    if (
      step.title === "Configure Source System" ||
      step.title === "Configure Target System"
    ) {
      return (props) => (
        <Component
          {...props}
          connectors={connectors}
          setConnectors={setConnectors}
          setData={setData}
          data={data}
          handleNext={handleNext}
        />
      );
    }
  
    return Component; // Return the static component for other steps
  };
  

  const StepComponent = getCurrentStepComponent();

  // Fetch connectors list from API on component mount
  useEffect(() => {
    fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/connectors`)
        .then((response) => response.json())
        .then((data) => {
            if (data.status === "success") {
                setConnectors(data.data);
            } else {
                console.error("Failed to fetch connectors:", data.detail);
            }
        })
        .catch((error) => console.error("Error fetching connectors:", error));
  }, []);

  const API_BASE_URL = process.env.REACT_APP_FASTAPI_URL;
  const COMMON_HEADERS = {
    'Content-Type': 'application/json',
  };
  
  // Helper to prepare project data
  const prepareProjectData = () => ({
    user_email: loggedInUserEmail,
    name: formData.projectname,
    source_configuration: {
      server_name: formData.servernames,
      database_name: formData.databasenames,
      user_name: formData.usernames,
      password: formData.passwords,
      excelfilename: formData.excelfilenames,
      filepath: formData.filepaths,
      host_name: formData.hostnames,
      port: formData.ports,
      protocol: formData.protocols,
      account_name: formData.accountnames,
      account_key: formData.accountkeys,
      containername: formData.containernames,
    },
    target_configuration: {
      server_name: formData.servernamet,
      database_name: formData.databasenamet,
      user_name: formData.usernamet,
      password: formData.passwordt,
      host_name: formData.hostnamet,
      port: formData.portt,
      protocol: formData.protocolt,
    },
    db_objects_selected: selectedObjects,
    load_type: formData.load_type,
  });
  
  // Helper to save the project
  const saveProject = async (projectData) => {
    const response = await fetch(
      `${API_BASE_URL}/v1/project/new_project?source_system=${formData.sourcesystem}&target_system=${formData.targetsystem}`,
      {
        method: 'POST',
        headers: COMMON_HEADERS,
        body: JSON.stringify(projectData),
      }
    );
    return response.json();
  };
  
  // Helper to execute project sync
  const executeProjectSync = async (projectId) => {
    const syncDataUrl = `${API_BASE_URL}/v1/data_sync/sync-data?project_id=${projectId}`;
    const response = await fetch(syncDataUrl, {
      method: 'GET',
      headers: COMMON_HEADERS,
    });
    return response.json();
  };
  
  // Save function
  const handleSave = async () => {
    console.log('Triggered Save function');
    setIsSaving(true);
  
    try {
      const projectData = prepareProjectData();
      const responseData = await saveProject(projectData);
  
      if (responseData.status === 'success') {
        console.log('Project saved successfully!');
        navigate('/all-projects');
      } else {
        console.error('Error saving project:', responseData.message);
      }
    } catch (error) {
      console.error('Error saving project:', error);
    } finally {
      setIsSaving(false);
    }
  };
  
  // Save and Run function
  const handleSaveAndRun = async () => {
    console.log('Triggered Save & Run function');
    setIsSaving2(true);
  
    try {
      const projectData = prepareProjectData();
      const responseData = await saveProject(projectData);
  
      if (responseData.status === 'success' && responseData.data.project_id) {
        setIsSaving2(false);
        setIsRunning(true);
        console.log('Project saved successfully with ID:', responseData.project_id);
        const syncResponse = await executeProjectSync(responseData.data.project_id);
  
        if (syncResponse.status === 'success') {
          console.log('New project executed successfully!');
          navigate('/all-projects');
        } else {
          console.error('Error executing new project:', syncResponse.message);
        }
      } else {
        console.error('Error saving project:', responseData.message);
      }
    } catch (error) {
      console.error('Error saving or executing project:', error);
    } finally {
      setIsRunning(false);
    }
  };
  


  return (
    <div className="flex-grow">
      <Toaster />
      <div className="sqlprojects-layout">
        {/* Breadcrumb Navigation */}
        <nav aria-label="breadcrumb-trails">
          <ul className="breadcrumb-unit">
            <BreadcrumbItem to="/" label="Home" isActive={false} />
            <BreadcrumbItem to="/all-projects" label="Active Projects" isActive={false} />
            <BreadcrumbItem to="/add-project" label="Add Projects" isActive={true} />
          </ul>
        </nav>

        {/* Progress Indicator */}
        <div className="add-projects-header-layout">{renderProgressIndicator()}</div>

        {/* Step Content */}
        <div className="add-projects-sql-card-layout">
          {StepComponent && (
            <StepComponent
              formData={formData}
              setFormData={setFormData}
              data = {data}
            />
          )}
        </div>

        <div
          className={
            currentPage === 1
              ? "navigation-buttons-single"
              : "navigation-buttons-add-project"
          }
        >
          {currentPage > 1 && (
            <button onClick={handlePrevious} className="next-button-add-project">
              Back
            </button>
          )}
          {currentPage < steps.length && (
            <button onClick={handleNext} className="next-button-add-project"  disabled={currentPage === 3 && !formData.sourceValidationStatus}>
              Next
            </button>
          )}
          {currentPage === steps.length && (
          <div className="final-step-buttons">
            <button
              onClick={handleSave}
              className={`save-button-add-project ${isSaving ? 'loading' : ''}`}
              disabled={isSaving} // Disable button while saving
            >
              {isSaving ? (
                <>
                  <FaSpinner className="spinner" /> Saving...
                </>
              ) : (
                "Save"
              )}
            </button>
            <button
              onClick={handleSaveAndRun}
              className="save-and-run-button-add-project"
              disabled={isSaving2 || isRunning}
            >
              {isSaving2 ? (
                <>
                  <FaSpinner className="spinner" /> Saving...
                </>
              ) : isRunning ? (
                <>
                  <FaSpinner className="spinner" /> Running...
                </>
              ) : (
                "Save And Run"
              )}
            </button>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};


export default AddProject;
