import '../style/LayoutStyles3.css';
import { FaBell, FaCog } from 'react-icons/fa';
import Sidebar3 from './Sidebar3';
import { Outlet } from 'react-router-dom';
import Sidebar4 from './Sidebar4';

const Layout3 = () => {
    return (    
        <div className='layout-main-container'>
            <div className='layout-main-header'>
                <div className={`logo-container`}>
                    <img src={require('../img/thumbnail_4Vue-V5-transformed.png')} alt="4SEER Logo"  className="logo-styles"/>
                </div>
                <div className='control-section-header'>
                    {/* <div className='welcome-text'> Hi, User</div> */}
                    <div className="search-bar">
                        <input type="text" placeholder="Search..." />
                    </div>
                    <div className="user-controls">
                        <FaBell className="icon notification-icon" />
                        <FaCog className="icon settings-icon" />
                    </div>
                </div>
            </div>
            <div className='layout-main-body'>
                <div className='layout-sidebar'>
                    <Sidebar4 />
                </div>
                <div className='page-layout'>
                    <Outlet />
                </div>
            </div>

        </div>
    )
}

export default Layout3;