import React, { useState } from 'react';
import '../../style/Signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ setIsSignup }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1); // 1: Login, 2: Verification, 3: Forgot Password Step 1, 4: Forgot Password Step 2
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/sign-in`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data.detail.verification_required) {
                    localStorage.setItem('temporaryToken', data.data?.refresh_token || '');
                    setStep(2);
                } else {
                    localStorage.setItem('token', data.data.refresh_token);
                    localStorage.setItem('user', JSON.stringify(data.data));
                    navigate('/');
                }
            } else {
                const errorDetail = data.detail?.detail || 'Login failed';
                if (data.detail?.verification_required) {
                    setError('User is not confirmed. Please verify your account.');
                    localStorage.setItem('temporaryToken', data.data?.refresh_token || '');
                    setStep(2); 
                } else {
                    setError(errorDetail);
                }
            }
        } catch (err) {
            console.error('Error during login:', err);
            setError('An error occurred during login');
        } finally {
            setLoading(false);
        }
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/confirm-sign-up`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username,
                    confirmation_code: verificationCode,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('token', data.data.refresh_token);
                localStorage.setItem('user', JSON.stringify(data.data));
                navigate('/');
            } else {
                setError('Invalid code');
            }
        } catch (err) {
            console.error('Error during verification:', err);
            setError('An error occurred during verification');
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/auth/forgot-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                setStep(4); // Move to next step for reset password
            } else {
                const data = await response.json();
                setError(data.detail || 'Request failed');
            }
        } catch (err) {
            console.error('Error during forgot password:', err);
            setError('An error occurred while processing your request');
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/cognito/auth/confirm-reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username,
                    confirmation_code: verificationCode,
                    new_password: newPassword,
                }),
            });

            if (response.ok) {
                alert('Password successfully reset. Please log in with your new password.');
                setStep(1); // Redirect to login
            } else {
                const data = await response.json();
                setError(data.detail || 'Reset failed');
            }
        } catch (err) {
            console.error('Error during reset password:', err);
            setError('An error occurred during password reset');
        } finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        setIsSignup(true);
    };

    return (
        <div className="signup-card-right">
            <h2>
                {step === 1 ? 'Login' :
                step === 2 ? 'Verify Code' :
                step === 3 ? 'Forgot Password' :
                'Reset Password'}
            </h2>
            {step === 1 && (
                <form onSubmit={handleLogin} className="login-form">
                    <div className="form-group-signup">
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                        />
                    </div>
                    <div className="form-group-signup">
                        <label>Password</label>
                        <div className="input-wrapper-password">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                            <span
                                className="password-toggle-icon-login-signup"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="form-group-signup form-group-full">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                    <div className="signup-footer">
                        <p>
                            <Link onClick={() => setStep(3)}>Forgot Password?</Link>
                        </p>
                        <p>
                            Don't have an account? <Link onClick={handleClick}>Sign up here</Link>
                        </p>
                    </div>
                </form>
            )}
            {step === 2 && (
                <form onSubmit={handleVerification} className="login-form">
                    <div className="form-group-signup">
                        <label>Verification Code</label>
                        <input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder="Enter verification code"
                            required
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="form-group-signup form-group-full">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Verifying...' : 'Verify'}
                        </button>
                    </div>
                </form>
            )}
            {step === 3 && (
                <form onSubmit={handleForgotPassword} className="login-form">
                    <div className="form-group-signup">
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your username"
                            required
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="form-group-signup form-group-full">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Sending request...' : 'Send Request'}
                        </button>
                    </div>
                </form>
            )}
            {step === 4 && (
                <form onSubmit={handleResetPassword} className="login-form">
                    <div className="form-group-signup">
                        <label>Verification Code</label>
                        <input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder="Enter verification code"
                            required
                        />
                    </div>
                    <div className="form-group-signup">
                        <label>New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            required
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="form-group-signup form-group-full">
                        <button type="submit" disabled={loading}>
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Login;
