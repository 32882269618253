import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import SearchFilterSort from '../SearchFilterSort';
// import '../../style/ProjectStatus.css';

const RunHistoryDMProjectLevel = () => { 
    const [filter, setFilter] = useState("All"); 
    const [sortBy, setSortBy] = useState({ field: 'Creation Date', isAscending: true });
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [data, setData] = useState([]);

    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/data-migration/manual-projectlevel-status?email=${loggedInUserEmail}`);
                const result = await response.json();
                console.log('DM Run History Projects level data from endpoint: ', result);
    
                if (result.status === "success" && Array.isArray(result.data)) {
                    setData(result.data);
                } else {
                    setData([]);  
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([]);  
            }
        };
        fetchProjects();
    }, [loggedInUserEmail]);
    

    const handleFilterChange = (e) => setFilter(e.target.value);

    const handleSortByChange = (e) => {
        const selectedField = e.target.value;
        setSortBy(prev => ({
            field: selectedField,
            isAscending: prev.field === selectedField ? !prev.isAscending : true,
        }));
    };

    const handleFilter = useCallback((filteredData) => {
            setFilteredProjects(filteredData);
        }, []);

    return (
            <div className="project-details-layout">
                <SearchFilterSort
                    data={data}
                    onFilter={handleFilter}
                    filterOptions={{ 'All': 'All', 'Active': 'Active', 'Inactive': 'In-Active' }}
                />
                <div className='projects-listing'>
                    <div className='project-table-header'>
                        <div className='project-column-header-project-status-tab'>Project Name</div>
                        <div className='project-column-header-project-status-tab'>Total Objects</div>
                        <div className='project-column-header-project-status-tab'>Load Type</div>
                        <div className='project-column-header-project-status-tab'>Run Date</div>
                        <div className='project-column-header-project-status-tab'>Status</div>
                    </div>
                    <div className="scrollable-box-sql-project-details">
                        {filteredProjects.length > 0 ? (
                            filteredProjects.map((project) => {
                                console.log("Project:", project);
                                console.log("Project ID:", project.projectid);

                                return (
                                    <div key={project.projectid} className='project-status-tab-row'>
                                        <Link 
                                            to={`/run-history-dm-entity-status`} 
                                            state={{ projectData: project }} 
                                            className="link-styling project-status-tab-column" 
                                            style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                                        >
                                            {project.projectname}
                                        </Link>                        
                                        <div className='project-status-tab-column'>{project.noofobjects}</div>
                                        <div className='project-status-tab-column'>{project.loadtype}</div>
                                        <div className='project-status-tab-column'>{new Date(project.rundate).toLocaleString()}</div>
                                        <div className='project-status-tab-column'>{project.status}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='no-projects'>No projects available</div>
                        )}
                    </div>                        
                </div>
            </div>
    );
}

export default RunHistoryDMProjectLevel;