import React, { useState } from 'react';
import '../../style/Incremental.css';
import '../../style/ScrollableBox.css';

const Incremental = ({ data, setSelectedObjects, selectedObjects }) => {
  const [activeTable, setActiveTable] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColumnsSource, setSelectedColumnsSource] = useState({});
  const [tableHasPrimaryKey, setTableHasPrimaryKey] = useState([]);

  const handleTableToggle = (tableName, isEnabled) => {
    console.log("Toggle triggered for table:", tableName, "Current state:", isEnabled);
    setSelectedColumnsSource((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [], isEnabled };
      }
      newSelectedColumns[tableName].isEnabled = !isEnabled;
      return newSelectedColumns;
    });
    setSelectedColumns((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [], isEnabled };
      }
      newSelectedColumns[tableName].isEnabled = !isEnabled;
      return newSelectedColumns;
    });
  };

  const sortedTables = data.data.slice().sort((table1, table2) => {
    const tableName1 = Object.keys(table1)[0];
    const tableName2 = Object.keys(table2)[0];
    const isEnabled1 = selectedColumnsSource[tableName1]?.isEnabled || false;
    const isEnabled2 = selectedColumnsSource[tableName2]?.isEnabled || false;

    if (isEnabled1 === isEnabled2) {
      return 0;
    }

    return isEnabled1 ? -1 : 1;
  });
  
  const handleTableClick = (tableName, hasPrimaryKey) => {
    setActiveTable((prevTable) => (prevTable === tableName ? null : tableName));
    setSelectedColumns((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [], isEnabled: false};
      }
      if(hasPrimaryKey){
        if (!newSelectedColumns[tableName].hashKey.includes(hasPrimaryKey)) {
          newSelectedColumns[tableName].hashKey.push(hasPrimaryKey);
        }
      } 
      setSelectedObjects(newSelectedColumns);  
      console.log('In handleTableClick, selectedObjects (payload): ', selectedObjects);
      // if (newSelectedColumns[tableName]) {
      //   newSelectedColumns[tableName].isEnabled = !selectedColumns[tableName]?.isEnabled;
      // }
      // console.log(newSelectedColumns[tableName].isEnabled, ' is IsEnabled value in selectedColumns');
      // console.log('In handleTableClick, selectedColumns: ', newSelectedColumns);
    return newSelectedColumns;
    });
    setSelectedColumnsSource((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [] };
      }
      const isColumnInHashKey = newSelectedColumns[tableName].hashKey.some(item => item.column === hasPrimaryKey);
      if (!isColumnInHashKey && hasPrimaryKey) {
        newSelectedColumns[tableName].hashKey.push({
          column: hasPrimaryKey,
          constraint_type: 'PRIMARY KEY',
          isSelected: true,            
        });
      }
      // if (newSelectedColumns[tableName]) {
      //   newSelectedColumns[tableName].isEnabled = !selectedColumns[tableName]?.isEnabled;
      // }
      console.log(newSelectedColumns[tableName].isEnabled, ' is IsEnabled value columnSource');
      console.log(newSelectedColumns, ' is table value ----------------------');
      return newSelectedColumns;
    })
  };

  const handleColumnSelect = (tableName, columnName, constraint_type) => {

    setSelectedColumnsSource((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
    
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [] };
      }
    
      const isColumnInCursorKey = newSelectedColumns[tableName].cursorKey.some(item => item.column === columnName);
      const isColumnInHashKey = newSelectedColumns[tableName].hashKey.some(item => item.column === columnName);
      if(tableHasPrimaryKey.includes(tableName)){
        if (!isColumnInCursorKey) {
          newSelectedColumns[tableName].cursorKey.push({
            column: columnName,
            constraint_type: constraint_type,
            isSelected: false,
          });
        }
      } else {
        if (!isColumnInCursorKey) {
          newSelectedColumns[tableName].cursorKey.push({
            column: columnName,
            constraint_type: constraint_type,
            isSelected: false,
          });
        }
        if (!isColumnInHashKey) {
          newSelectedColumns[tableName].hashKey.push({
            column: columnName,
            constraint_type: constraint_type,
            isSelected: false,            
          });
        }
      }
    
      return newSelectedColumns;
    });
     
  };

  const handleCheckboxChange = (tableName, columnName, keyType, isChecked, constraint_type) => {
    setSelectedColumns((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
  
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [] };
      }
  
      if (isChecked) {
        if (!newSelectedColumns[tableName][keyType].includes(columnName)) {
          newSelectedColumns[tableName][keyType].push(columnName);
        }
      } else {
        newSelectedColumns[tableName][keyType] = newSelectedColumns[tableName][keyType].filter(
          (col) => col !== columnName
        );
      }
      console.log('In handleCheckBoxChange, selectedColumns: ', newSelectedColumns);
      setSelectedObjects(newSelectedColumns);  
      console.log('In handleCheckBoxChange, selectedObjects (payload): ', selectedObjects);
      return newSelectedColumns;
    });
  
    setSelectedColumnsSource((prevSelectedColumns) => {
      const newSelectedColumns = { ...prevSelectedColumns };
  
      if (!newSelectedColumns[tableName]) {
        newSelectedColumns[tableName] = { cursorKey: [], hashKey: [] };
      }
      if(keyType === 'hashKey' && constraint_type==='PRIMARY KEY'){
        return newSelectedColumns;
      } else {
      newSelectedColumns[tableName][keyType].forEach((column) => {
        if (column.column === columnName) {
          column.isSelected = isChecked;
        }
      });
    }
  
      console.log('Selected Source Columns:', newSelectedColumns);
  
      return newSelectedColumns;
    });
  };
  

  return (
    <div className={'incremental-container-mapping'}>
      <div className='incremental-dialog'>
        <div className='first-column scrollable-box'>
            {sortedTables.map((table, index) => {
            const tableName = Object.keys(table)[0];            
            const isEnabled = selectedColumnsSource[tableName] && selectedColumnsSource[tableName].isEnabled !== undefined
            ? selectedColumnsSource[tableName].isEnabled  
            : false;

            console.log('isEnabled value in return statement: ', isEnabled);
            const className = `table-name ${activeTable === tableName ? 'active' : ''} ${isEnabled ? '' : 'disabled'}`
            // const primaryKeyColumn = Object.values(table[tableName])
            const columnWithPrimaryKey = Object.entries(table[tableName]).filter(
              (column) =>{ 
                return column[1].constraint_type === 'PRIMARY KEY'}
            )[0];

            const hasPrimaryKey = columnWithPrimaryKey?.[0];

            if (hasPrimaryKey && !tableHasPrimaryKey.includes(tableName)) {
              setTableHasPrimaryKey((prevTables) => [...prevTables, tableName]);
            }
            return(
            <div key={index}>
              <div
                className = {`table-name ${activeTable === tableName ? 'active' : ''}`}                
                onClick={() => handleTableClick(tableName, hasPrimaryKey, isEnabled)}
              > 
                  <label className="toggle-switch-incremental small-toggle" style={{ position: 'relative', display: 'inline-block' }}>
                  <input
                    type="checkbox"
                    className= {className} 
                    checked={isEnabled}
                    onChange={() => handleTableToggle(tableName, isEnabled)}
                  />

                  <span className="slider-incremental"></span>
                  </label>
                <span style={{ wordWrap: 'break-word' }}>{tableName}</span>
                <span
                  className={`arrow ${activeTable === tableName ? 'down' : 'right'}`}
                >
                  &#9654;
                </span>
              </div>
              {activeTable === tableName && (
                <div className='column-names'>
                  {Object.keys(table[tableName]).map((columnName) => (
                    <div
                      key={columnName}
                      style={{ wordWrap: 'break-word' }}
                      onClick={() =>{
                        const constraint_type = table[tableName][columnName].constraint_type;
                        if(constraint_type === 'PRIMARY KEY'){
                          
                        }
                        handleColumnSelect(tableName, columnName, constraint_type)
                      }}
                      className={`column-name ${
                        selectedColumns[tableName] &&
                        selectedColumns[tableName].cursorKey.includes(columnName)
                          ? 'selected'
                          : ''
                      }`}
                    >
                      {columnName}
                      {table[tableName][columnName].constraint_type === 'PRIMARY KEY' && ' (Primary Key)'}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )})}
        </div>
        <div className='second-column scrollable-box'>
          <div className='destination-column-headings'>
            <div className='destination-column'>Cursor Key</div>
            <div className='destination-column'>Hash Key</div>
          </div>
          <div className='destination-columns'>
            <div className='destination-column'>
              <div className='selected-columns'>
                {activeTable &&
                  selectedColumnsSource[activeTable] &&
                  selectedColumnsSource[activeTable].cursorKey.map((column) => (
                    <div key={column.column}>
                      <label>
                        <input
                          type='checkbox'
                          checked={selectedColumns[activeTable].cursorKey.includes(column.column) &&
                            column.isSelected}
                          onChange={(e) =>
                            handleCheckboxChange(
                              activeTable,
                              column.column,
                              'cursorKey',
                              e.target.checked
                            )
                          }
                        />
                        {column.column}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className='destination-column'>
              <div className='selected-columns'>
                {activeTable &&
                  selectedColumnsSource[activeTable] &&
                  selectedColumnsSource[activeTable].hashKey.map((column) => (
                    <div key={column.column}>
                      <label>
                        <input
                          type='checkbox'
                          checked={selectedColumns[activeTable].hashKey.includes(column.column) &&
                            column.isSelected}
                          onChange={(e) => {
                            if(column.constraint_type!=='PRIMARY KEY'){
                            handleCheckboxChange(activeTable, column.column, 'hashKey', e.target.checked, column.constraint_type);
                          }}
                        }
                          
                        />
                        {column.column}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Incremental;
