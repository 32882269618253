import React, { useState } from 'react';
import { BsRobot, BsPerson } from 'react-icons/bs';
import { FaCheckCircle } from "react-icons/fa"; // Use react-icons here
import { FaCopy, FaDownload  } from "react-icons/fa6";
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import DataVisualization from './DataVisualization';
import './../../style/AIChatBot/MessageList.css'

export default function MessageList({ messages }) {
  const [copiedTable, setCopiedTable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const copyTableToClipboard = async (headers, rows, messageId) => {
  //   const headerRow = headers.join('\t');
  //   const dataRows = rows.map(row => row.join('\t')).join('\n');
  //   const tableData = `${headerRow}\n${dataRows}`;

  //   try {
  //     await navigator.clipboard.writeText(tableData);
  //     setCopiedTable(messageId);
  //     setTimeout(() => setCopiedTable(null), 2000);
  //   } catch (err) {
  //     console.error('Failed to copy table data:', err);
  //   }
  // };

  const copyTableToClipboard = async (headers, rows, messageId) => {
    try {
      // Create a formatted table string that preserves columns
      const formatRow = (row) => row.map(cell => String(cell).padEnd(20)).join('\t');
      
      const headerRow = formatRow(headers);
      const dataRows = rows.map(row => formatRow(row));
      const tableData = [headerRow, ...dataRows].join('\n');
  
      // Primary method using Clipboard API
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(tableData);
      } else {
        // Fallback method for non-HTTPS or unsupported browsers
        const textArea = document.createElement('textarea');
        textArea.value = tableData;
        textArea.style.position = 'fixed';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        textArea.select();
        
        try {
          document.execCommand('copy');
          document.body.removeChild(textArea);
        } catch (err) {
          console.error('Fallback: Copying failed', err);
          document.body.removeChild(textArea);
          throw new Error('Copying failed');
        }
      }
  
      // Visual feedback
      setCopiedTable(messageId);
      setTimeout(() => setCopiedTable(null), 2000);
  
    } catch (err) {
      console.error('Failed to copy table data:', err);
      // You might want to add a state for error feedback
      setError('Failed to copy table data to clipboard');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDownloadCsv = async (headers, rows) => {
    setLoading(true);
    setError(null);

    try {
      const csvRows = [];
      csvRows.push(headers.join(','));
      rows.forEach(row => csvRows.push(row.join(',')));
      const csvData = csvRows.join('\n');

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'query_results.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError('An error occurred while processing your request.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const renderTable = (headers, rows, messageId) => (
  //   <div className="table-container-message-list ">
  //     <div className="table-actions">
  //       <button
  //         onClick={() => copyTableToClipboard(headers, rows, messageId)}
  //         className={`table-action-btn ${copiedTable === messageId ? 'copied' : ''}`}
  //       >
  //         {copiedTable === messageId ? (
  //           <>
  //             <FaCheckCircle size={16} className="icon-copied" />
  //             <span>Copied!</span>
  //           </>
  //         ) : (
  //           <>
  //             <FaCopy size={16} />
  //             <span>Copy table</span>
  //           </>
  //         )}
  //       </button>
  //       <button
  //         onClick={() => handleDownloadCsv(headers, rows)}
  //         className="table-action-btn"
  //       >
  //         {loading ? 'Loading...' : <>
  //           <FaDownload size={16} />
  //           <span>Download CSV</span>
  //         </>}
  //       </button>
  //     </div>
  //     <div className="table-scroll">
  //       <table>
  //         <thead>
  //           <tr>
  //             {headers.map((header, index) => (
  //               <th key={index}>{header}</th>
  //             ))}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {rows.map((row, rowIndex) => (
  //             <tr key={rowIndex}>
  //               {row.map((cell, cellIndex) => (
  //                 <td key={cellIndex}>{cell}</td>
  //               ))}
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );

  const renderTable = (headers, rows, messageId) => (
    <div className="table-container-message-list">
      <div className="table-actions">
        <button
          onClick={() => copyTableToClipboard(headers, rows, messageId)}
          className={`table-action-btn ${copiedTable === messageId ? 'copied' : ''}`}
          aria-label="Copy table data"
        >
          {copiedTable === messageId ? (
            <>
              <FaCheckCircle size={16} className="icon-copied" />
              <span>Copied!</span>
            </>
          ) : (
            <>
              <FaCopy size={16} />
              <span>Copy table</span>
            </>
          )}
        </button>
        <button
          onClick={() => handleDownloadCsv(headers, rows)}
          className="table-action-btn"
          aria-label="Download as CSV"
        >
          {loading ? 'Loading...' : <>
            <FaDownload size={16} />
            <span>Download CSV</span>
          </>}
        </button>
      </div>
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      <div className={`table-scroll ${loading ? 'loading' : ''}`}>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderMessage = message => (
    <div className={`message ${message.type === 'user' ? 'user-message' : 'bot-message'}`}>
      {message.tableData && (
        <>
          {renderTable(message.tableData.headers, message.tableData.rows, message.id)}
          {message.visualizationType && (
            <DataVisualization
              data={message.tableData.rows}
              headers={message.tableData.headers}
              visualizationType={message.visualizationType}
            />
          )}
        </>
      )}
      <ReactMarkdown className="message-content">{message.content}</ReactMarkdown>
      <span className="message-timestamp">
        {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </span>
    </div>
  );

  return (
    <div className="message-list">
      {messages.map((message, index) => (
        <motion.div
          key={message.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 * index }}
          className={`message-wrapper ${message.type === 'user' ? 'user' : 'bot'}`}
        >
          {/* <div className="icon">
            {message.type === 'user' ? <BsPerson size={20} /> : <BsRobot size={20} />}
          </div> */}
          {renderMessage(message)}
        </motion.div>
      ))}
    </div>
  );
}  
